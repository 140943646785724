import { FC, HTMLAttributes } from 'react';

import { useMediaQuery } from 'react-responsive';
import { Container, Content, FAB, Text } from './styles';
import Row from '../Row';
import { ProvefacilLogoColorful } from '../../assets/vectors/logo';
import { ArrowRightIcon, InstagramIcon } from '../../assets/vectors/icons';
import { anchorTo, ThemeNameEnum } from '../../utils';
import Column from '../Column';

type FooterProps = HTMLAttributes<HTMLDivElement>;

const Footer: FC<FooterProps> = (props) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  return (
    <Container {...props}>
      <Content>
        {isMobile ? (
          <Column style={{ width: '100%', gap: 20 }}>
            <Row style={{ flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
              <a href="#header">
                <ProvefacilLogoColorful style={{ minWidth: 150, width: 150, height: 'auto', marginLeft: -3 }} />
              </a>
              <a href="https://www.instagram.com/provefacil/">
                <InstagramIcon style={{ width: 24, height: 24, color: 'var(--quaternary-600)' }} />
              </a>
            </Row>
            <Text>Desenvolvido por Provefácil LTDA em 2023</Text>
          </Column>
        ) : (
          <>
            <a href="#header">
              <ProvefacilLogoColorful style={{ minWidth: 150, width: 150, height: 'auto', marginLeft: -3 }} />
            </a>
            <Row style={{ flex: 1, gap: 20, justifyContent: 'space-between' }}>
              <Text>Desenvolvido por Provefácil LTDA em 2023</Text>
              <a target="_blank" href="https://www.instagram.com/provefacil/" rel="noreferrer">
                <InstagramIcon style={{ width: 24, height: 24, color: 'var(--quaternary-600)' }} />
              </a>
            </Row>
          </>
        )}
        <FAB themeName={ThemeNameEnum.QUATERNARY} onClick={() => anchorTo()}>
          <ArrowRightIcon
            style={{
              width: 18,
              height: 18,
              minWidth: 18,
              minHeight: 18,
              maxWidth: 18,
              maxHeight: 18,
              transform: 'rotate(-90deg)',
            }}
          />
        </FAB>
      </Content>
    </Container>
  );
};

export default Footer;
