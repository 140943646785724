import { FC, SVGProps } from 'react';
import { AbsolutePosition } from '../../utils';
import { Center, Container } from './styles';

const CIRCLE_DRAW_SIZE = 100;

export type CircleProps = {
  size: number | string;
  center?: boolean;
  half?: boolean;
} & SVGProps<SVGSVGElement>;

const Circle: FC<CircleProps> = ({ size, center, half, style, children, ...rest }) => (
  <Container style={{ width: size, height: size, ...style }}>
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${CIRCLE_DRAW_SIZE} ${CIRCLE_DRAW_SIZE}`}
      style={AbsolutePosition.getPlainObject()}
      {...rest}
    >
      {half ? (
        <path
          d={`M 0 ${CIRCLE_DRAW_SIZE / 2} A ${CIRCLE_DRAW_SIZE / 2} ${
            CIRCLE_DRAW_SIZE / 2
          } 0 0 1 ${CIRCLE_DRAW_SIZE} ${CIRCLE_DRAW_SIZE / 2} L 0 ${CIRCLE_DRAW_SIZE / 2} Z`}
        />
      ) : (
        <circle cx={CIRCLE_DRAW_SIZE / 2} cy={CIRCLE_DRAW_SIZE / 2} r={CIRCLE_DRAW_SIZE / 2} />
      )}
    </svg>
    {center ? <Center>{children}</Center> : children}
  </Container>
);

export default Circle;
