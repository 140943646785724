import { FC, HTMLAttributes } from 'react';
import { IntegrationIcon, ModelThreeDIcon, TShirtIcon } from '../../assets/vectors/icons';

import { Container, Content, Icon, Text } from './styles';

type HighlightData = {
  id: number;
  Icon: FC<any>;
  text: string;
};

const HighlightsCard: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const highlights: HighlightData[] = [
    {
      id: 1,
      Icon: IntegrationIcon,
      text: 'Integração com todos os ecommerces',
    },
    {
      id: 2,
      Icon: ModelThreeDIcon,
      text: 'Modelo de corpo 3D personalizado',
    },
    {
      id: 3,
      Icon: TShirtIcon,
      text: 'Roupas, sapatos, acessórios e outros',
    },
  ];

  return (
    <Container {...props}>
      {highlights.map((highlight) => (
        <Content key={highlight.id}>
          <Icon>
            <highlight.Icon style={{ width: 30, height: 30 }} />
          </Icon>
          <Text>{highlight.text}</Text>
        </Content>
      ))}
    </Container>
  );
};

export default HighlightsCard;
