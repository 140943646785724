import { FC, HTMLAttributes, useCallback, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import { EmailIcon, WhatsappIcon } from '../../assets/vectors/icons';
import Column from '../../components/Column';
import { Form } from '../../components/Form';

import { ContactRow, ContactText, Container, SuccessText, Text, Title } from './styles';
import Input, { InputProps } from '../../components/Input';
import Button from '../../components/Button';
import { ThemeNameEnum } from '../../utils';
import Yup from '../../utils/Yup';

type FormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

const schema = Yup.object({
  name: Yup.string().min(3).nonNullable().required(),
  email: Yup.string().email().nonNullable().required(),
  phone: Yup.string().nonNullable().isValidPhone().required(),
  message: Yup.string().min(10).nonNullable(),
});

type ContactProps = HTMLAttributes<HTMLDivElement>;

const Contact: FC<ContactProps> = (props) => {
  // Refs
  const formRef = useRef<HTMLFormElement | null>();

  const { control, handleSubmit, setFocus, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
  });
  const [success, setSuccess] = useState(false);

  const title = `Entre em contato com a Prove Fácil`;
  const text = `Dúvidas? Nossa equipe\nestá à disposição.`;

  const contacts = [
    {
      id: 1,
      Icon: EmailIcon,
      text: 'comercial@provefacil.com.br',
    },
    {
      id: 1,
      Icon: WhatsappIcon,
      text: '(47) 9 9725 1319',
    },
  ];

  const onKeyUpSetFocusTo: (fieldName: FieldPath<FormData>) => InputProps['onKeyUp'] = useCallback(
    (fieldName) => (e) => {
      if (e.key === 'Enter') {
        setFocus(fieldName);
      }
    },
    [setFocus],
  );

  const onSubmit = useCallback(
    (data: FormData) => {
      setSuccess(false);
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('email', data.email);
      formData.append('phone', data.phone);
      formData.append('message', data.message);
      fetch(
        'https://script.google.com/macros/s/AKfycbwxlpUo4EkebqGm7QR3Cvr97vdFfG5xApM-Lk4YjCnir8VWx_6sCer6T9SxFi9r0xpUjA/exec',
        {
          method: 'POST',
          mode: 'no-cors',
          body: formData,
        },
      ).then((res) => {
        setSuccess(true);
        reset();
      });
    },
    [reset],
  );

  return (
    <Container {...props}>
      <Column style={{ flex: 1, gap: 40 }}>
        <Column style={{ gap: 20 }}>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Column>
        <Column style={{ gap: 20 }}>
          {contacts.map((contact) => (
            <ContactRow key={contact.text}>
              <contact.Icon style={{ width: 24, height: 24, color: 'var(--secondary)' }} />
              <ContactText>{contact.text}</ContactText>
            </ContactRow>
          ))}
        </Column>
      </Column>
      <Column
        style={{ position: 'relative', flex: 2, justifyContent: 'center', alignItems: 'center', minWidth: 100 }}
      >
        <Form
          ref={(ref) => {
            formRef.current = ref;
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="name"
            control={control}
            render={({ field, formState: { errors, touchedFields } }) => (
              <Input
                {...field}
                label="Nome"
                error={errors[field.name]}
                isTouched={touchedFields[field.name] === true}
                onKeyUp={onKeyUpSetFocusTo('email')}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, formState: { errors, touchedFields } }) => (
              <Input
                {...field}
                label="E-mail"
                error={errors[field.name]}
                isTouched={touchedFields[field.name] === true}
                onKeyUp={onKeyUpSetFocusTo('phone')}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field, formState: { errors, touchedFields } }) => (
              <Input
                {...field}
                type="tel"
                label="Telefone"
                error={errors[field.name]}
                isTouched={touchedFields[field.name] === true}
                onKeyUp={onKeyUpSetFocusTo('message')}
              />
            )}
          />
          <Controller
            name="message"
            control={control}
            render={({ field, formState: { errors, touchedFields } }) => (
              <Input
                {...field}
                type="textarea"
                label="Mensagem"
                error={errors[field.name]}
                isTouched={touchedFields[field.name] === true}
              />
            )}
          />
          <Button type="submit" themeName={ThemeNameEnum.PRIMARY} style={{ alignSelf: 'flex-start' }}>
            Enviar
          </Button>
          {success && <SuccessText>Mensagem enviada com successo!</SuccessText>}
        </Form>
      </Column>
    </Container>
  );
};

export default Contact;
