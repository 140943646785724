import { FC, HTMLAttributes } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as IntegrationDiagram } from '../../assets/vectors/integration-diagram.svg';
import { ReactComponent as IntegrationDiagramMobile } from '../../assets/vectors/integration-diagram-mobile.svg';

import { Container, LeftContent, RightContent, Text, Title } from './styles';
import { AbsolutePosition } from '../../utils';

type IntergrateWithEverythingProps = HTMLAttributes<HTMLDivElement>;

const IntegrateWithEverything: FC<IntergrateWithEverythingProps> = (props) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const title = `Integre com tudo`;
  const text = `Integração com todas as plataformas de e-commerce do mercado e com a lista de produtos automaticamente através do Google Merchant`;

  return (
    <Container {...props}>
      <LeftContent>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </LeftContent>
      <RightContent>
        {isMobile ? (
          <div style={{ position: 'relative', display: 'flex', flexGrow: 1, width: '100%', height: '100%' }}>
            <IntegrationDiagramMobile height="100%" width="100%" style={AbsolutePosition.getPlainObject()} />
          </div>
        ) : (
          <IntegrationDiagram style={{ display: 'flex', flex: 1, width: '100%' }} />
        )}
      </RightContent>
    </Container>
  );
};

export default IntegrateWithEverything;
