import styled from 'styled-components';
import Column from '../../components/Column';
import Row from '../../components/Row';

export const Container = styled.section`
  align-self: center;
  padding-top: 70px;
  gap: 80px;
  overflow: hidden;
`;

export const Clients = styled(Row)`
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding-bottom: 25px;
`;

export const ClientLogoContainer = styled(Column)`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 100px;
`;
