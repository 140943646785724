import { FC } from 'react';
import { RowProps } from '../Row';

import { Container, SizeBox, Text } from './styles';

export type BestOptionProps = {
  size: string;
} & RowProps;

const BestOption: FC<BestOptionProps> = ({ size, ...props }) => (
  <Container {...props}>
    <SizeBox>{size}</SizeBox>
    <Text>É a melhor opção</Text>
  </Container>
);

export default BestOption;
