import { CSSProperties } from 'react';
import { css } from 'styled-components';

export enum ThemeNameEnum {
  PRIMARY = 'primary',
  PRIMARY_100 = 'primary-100',
  SECONDARY = 'secondary',
  SECONDARY_100 = 'secondary-100',
  TERTIARY = 'tertiary',
  TERTIARY_600 = 'tertiary-600',
  TERTIARY_300 = 'tertiary-300',
  QUATERNARY = 'quaternary',
  QUATERNARY_600 = 'quaternary-600',
  QUATERNARY_400 = 'quaternary-400',
  QUATERNARY_300 = 'quaternary-300',
  CLEAR = 'clear',
  WHITE = 'white',
}

export type ThemeNameProps = {
  themeName?: ThemeNameEnum;
};

const AbsolutePositionCSS = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const AbsolutePositionObject = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const AbsolutePosition = {
  ...AbsolutePositionObject,
  getPlainObject: () => AbsolutePositionObject as Partial<CSSProperties>,
  toString: () => AbsolutePositionCSS,
};

export enum BodyPartEnum {
  ARM = 'ARM',
  CHEST = 'CHEST',
  CALF = 'CALF',
  FIST = 'FIST',
  FOOT = 'FOOT',
  HEAD = 'HEAD',
  HIP = 'HIP',
  LEG = 'LEG',
  THIGH = 'THIGH',
  WAIST = 'WAIST',
  SLEEVE = 'SLEEVE',
}

export enum FittingComfortCommon {
  VERY_TIGHT = -3,
  TIGHT,
  SLIGHTLY_TIGHT,
  BEST_FIT,
  SLIGHTLY_LOOSE,
  BAGGY,
  VERY_BAGGY,
}

export const fittingComfortCommonColors = {
  [FittingComfortCommon.VERY_TIGHT]: 'var(--danger)',
  [FittingComfortCommon.TIGHT]: 'var(--warning)',
  [FittingComfortCommon.SLIGHTLY_TIGHT]: 'var(--warning)',
  [FittingComfortCommon.BEST_FIT]: '#12b159',
  [FittingComfortCommon.SLIGHTLY_LOOSE]: 'var(--warning)',
  [FittingComfortCommon.BAGGY]: 'var(--warning)',
  [FittingComfortCommon.VERY_BAGGY]: 'var(--danger)',
};

/**
 * Scrolls to the specified element
 * @param element the element that will be displayed
 * @param notSmooth
 */
export const anchorToElement = (element?: HTMLElement, notSmooth?: boolean) => {
  element?.scrollIntoView({
    behavior: notSmooth ? 'auto' : 'smooth',
  });
};

/**
 * Scrolls to the specified element or to the top of the screen
 * @param id id of the element that will be displayed (if not defined, it will be anchored at the top)
 * @param notSmooth
 */
export const anchorTo = (id?: string, notSmooth?: boolean) => {
  const element = id ? document.getElementById(id) : document.body;
  if ((element ?? false) === element) {
    anchorToElement(element, notSmooth);
  }
};

// eslint-disable-next-line prettier/prettier
export const separeArrayPerChunks = <T=unknown>(array: T[], chunkSize: number) =>
  array.reduce((acc, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }

    acc[chunkIndex].push(item);

    return acc;
  }, [] as T[][]);
