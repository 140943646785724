import { FC, PropsWithChildren, SVGProps } from 'react';
import { RowProps } from '../Row';

import { Container, ICON_SIZE, Text } from './styles';
import Circle from '../Circle';
import { AbsolutePosition, fittingComfortCommonColors, FittingComfortCommon, BodyPartEnum } from '../../utils';
import { CheckRoundedIcon, CloseRoundedIcon, WarningIcon } from '../../assets/vectors/icons';

export enum FittingComfort {
  VERY_TIGHT, // muito apertado
  TIGHT, // apertado
  SLIGHTLY_TIGHT, // levemente apertado
  BEST_FIT, // Perfeito
  SLIGHTLY_LOOSE, // levemente folgado
  BAGGY, // folgado
  VERY_BAGGY, // muito folgado
}

export const FittingComfortWritten = {
  [FittingComfortCommon.VERY_TIGHT]: 'Justo',
  [FittingComfortCommon.TIGHT]: 'Levemente justo',
  [FittingComfortCommon.SLIGHTLY_TIGHT]: 'Levemente justo',
  [FittingComfortCommon.BEST_FIT]: 'Perfeito!',
  [FittingComfortCommon.SLIGHTLY_LOOSE]: 'Levemente folgado',
  [FittingComfortCommon.BAGGY]: 'Levemente folgado',
  [FittingComfortCommon.VERY_BAGGY]: 'Folgado',
};

export const FittingComfortLengthWritten = {
  [FittingComfortCommon.VERY_TIGHT]: 'Curto',
  [FittingComfortCommon.TIGHT]: 'Levemente curto',
  [FittingComfortCommon.SLIGHTLY_TIGHT]: 'Levemente curto',
  [FittingComfortCommon.BEST_FIT]: 'Perfeito!',
  [FittingComfortCommon.SLIGHTLY_LOOSE]: 'Levemente comprido',
  [FittingComfortCommon.BAGGY]: 'Levemente comprido',
  [FittingComfortCommon.VERY_BAGGY]: 'Comprido',
};

export const checkIfIsLengthMode = (bodyPart: BodyPartEnum) =>
  [BodyPartEnum.SLEEVE, BodyPartEnum.LEG].includes(bodyPart);

export type FittingComfortBoxProps = {
  lengthMode?: boolean;
  fittingComfortValue: number | undefined;
} & RowProps;

const FittingComfortBox: FC<FittingComfortBoxProps> = ({ fittingComfortValue, lengthMode, style, ...props }) => {
  const Icon = (
    _props: SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    },
  ) => {
    const size = Math.max(
      isNaN(_props?.width as number) ? 0 : (_props.width as number),
      isNaN(_props?.height as number) ? 0 : (_props.height as number),
    );

    const IconCircle: FC<PropsWithChildren> = ({ children: iconCircleChildren }) => (
      <Circle center size={size} fill={_props?.color || '#000'} style={{ position: 'relative' }}>
        <div
          style={{
            ...AbsolutePosition.getPlainObject(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {iconCircleChildren}
        </div>
      </Circle>
    );

    switch (fittingComfortValue) {
      case FittingComfortCommon.TIGHT:
      case FittingComfortCommon.BAGGY:
      case FittingComfortCommon.SLIGHTLY_TIGHT:
      case FittingComfortCommon.SLIGHTLY_LOOSE:
        return <WarningIcon {..._props} />;
      case FittingComfortCommon.VERY_TIGHT:
      case FittingComfortCommon.VERY_BAGGY:
        return (
          <IconCircle>
            <CloseRoundedIcon width={7} height={7} color="#ffffff" />
          </IconCircle>
        );
      case FittingComfortCommon.BEST_FIT:
        return (
          <IconCircle>
            <CheckRoundedIcon width={8.5} height={6.5} color="#ffffff" />
          </IconCircle>
        );
      default:
        return <></>;
    }
  };

  return (
    <Container
      style={{
        gap: 15,
        borderColor: fittingComfortCommonColors[fittingComfortValue as keyof typeof fittingComfortCommonColors],
        ...style,
      }}
      {...props}
    >
      <Icon
        width={ICON_SIZE}
        height={ICON_SIZE}
        style={{ minHeight: ICON_SIZE, minWidth: ICON_SIZE }}
        color={fittingComfortCommonColors[fittingComfortValue as keyof typeof fittingComfortCommonColors]}
      />
      <Text>
        {lengthMode
          ? FittingComfortLengthWritten[fittingComfortValue as keyof typeof FittingComfortLengthWritten]
          : FittingComfortWritten[fittingComfortValue as keyof typeof FittingComfortWritten]}
      </Text>
    </Container>
  );
};

export default FittingComfortBox;
