import styled from 'styled-components';
import { FemaleSmallMannequin } from '../../assets/vectors/mannequins';
import Column from '../../components/Column';
import Row from '../../components/Row';

export const MANNEQUIN_WIDTH = 275;

export const Container = styled.section`
  align-self: center;
  justify-content: center;
  padding-top: 40px;
  padding: 0;

  @media only screen and (max-width: 1150px) {
    padding-top: 80px;
  }
`;

export const Content = styled(Row)`
  justify-content: center;
  align-items: center;
  gap: 0 40px;
  overflow: hidden;
  max-width: 100%;

  @media only screen and (max-width: 1150px) {
    flex-direction: column;
    gap: 50px;
  }
`;

export const LeftContent = styled(Column)`
  flex: 1;
  align-items: flex-start;
  gap: 20px;

  @media only screen and (max-width: 1150px) {
    gap: 30px;
  }
`;

export const RightContent = styled(Row)`
  position: relative;
  gap: 20px;
  height: 546px;
  max-height: 546px;

  @media only screen and (max-width: 600px) {
    gap: 0;
    padding-right: 50px;
  }

  ::before {
    content: '';
    position: absolute;
    left: -${MANNEQUIN_WIDTH / 2}px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(56, 152, 232, 0.4) 0%, rgba(56, 152, 232, 0) 75.56%);
    width: 650px;
    height: 650px;
    z-index: -1;
    align-self: center;
  }
`;

export const Title = styled.h1`
  white-space: pre-wrap;
`;

export const Text = styled.p`
  font-size: 20px;
  padding-top: 28px;
  white-space: pre-wrap;

  @media only screen and (max-width: 600px) {
    text-align: justify;
  }
`;

export const MannequinWithBlurContainer = styled(Row)`
  position: relative;
  overflow: visible;
`;

export const MannequinContainer = styled(Column)`
  position: relative;
  width: ${MANNEQUIN_WIDTH}px;
  max-width: ${MANNEQUIN_WIDTH}px;
  height: 100%;
`;

export const CustomFemaleSmallMannequin = styled(FemaleSmallMannequin)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: auto;

  .CHEST {
    stroke: var(--success);
  }

  .WAIST {
    stroke: var(--success);
  }

  .HIP {
    stroke: var(--warning);
  }
`;

export const FittingComfortBoxes = styled(Column)`
  gap: 20px;
  padding-top: 20px;

  @media only screen and (max-width: 600px) {
    padding-top: 60px;
    align-items: flex-start;
  }
`;
