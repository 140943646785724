import { FC, HTMLAttributes, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import { separeArrayPerChunks } from '../../utils';
import ForceSportWearImage from '../../assets/images/clients/force-sport-wear.png';
import MeuJeansImage from '../../assets/images/clients/meu-jeans.png';
import RosaAzulKidsImage from '../../assets/images/clients/rosa-azul-kids.png';

import { ClientLogoContainer, Clients, Container } from './styles';

type Client = {
  id: number;
  label: string;
  image: string;
};

type Slide = Client[];

type OurClientsProps = HTMLAttributes<HTMLDivElement>;

const OurClients: FC<OurClientsProps> = (props) => {
  const isTablet = useMediaQuery({
    query: '(max-width: 1150px)',
  });

  const isSmallTablet = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const title = `Nossos clientes`;

  const allClients: Client[] = useMemo(
    () => [
      {
        id: 1,
        label: 'Logo Force sport wear',
        image: ForceSportWearImage,
      },
      {
        id: 2,
        label: 'Logo Meu Jeans',
        image: MeuJeansImage,
      },
      {
        id: 3,
        label: 'Logo Rosa Azul Kids',
        image: RosaAzulKidsImage,
      },
    ],
    [],
  );

  const carousel: Slide[] = useMemo(() => {
    if (isMobile) {
      return separeArrayPerChunks(allClients, 2);
    }

    if (isSmallTablet) {
      return separeArrayPerChunks(allClients, 3);
    }

    if (isTablet) {
      return separeArrayPerChunks(allClients, 4);
    }

    return separeArrayPerChunks(allClients, 5);
  }, [allClients, isMobile, isSmallTablet, isTablet]);

  return (
    <Container {...props}>
      <h3 style={{ alignSelf: 'center' }}>{title}</h3>
      <Carousel showThumbs={isMobile} showStatus={false} showArrows={false} showIndicators={carousel?.length > 1}>
        {carousel.map((slide, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Clients key={index}>
            {slide?.map((client) => (
              <ClientLogoContainer key={client.id}>
                <img
                  alt={client.label}
                  src={client.image}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: 200,
                    maxHeight: 80,
                    objectFit: 'contain',
                  }}
                />
              </ClientLogoContainer>
            ))}
          </Clients>
        ))}
      </Carousel>
    </Container>
  );
};

export default OurClients;
