import styled, { css } from 'styled-components';
import Card from '../../components/Card';
import Row from '../../components/Row';

export const Container = styled.section`
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
  gap: 50px;

  @media only screen and (max-width: 1150px) {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    margin: 30px 0;
  }
`;

export const Title = styled.h4``;

export const Text = styled.p`
  white-space: pre-wrap;
`;

export const SuccessText = styled.p`
  white-space: pre-wrap;
  color: #008800;
`;

const cardCommonStyles = css`
  align-self: flex-start;
  align-items: center;
  padding: 0 15px;
  height: 80px;
`;

export const GoogleShoppingCard = styled(Card)`
  ${cardCommonStyles}
`;

export const ProveFacilCard = styled(Card)`
  ${cardCommonStyles}
`;

export const ContactRow = styled(Row)`
  align-items: center;
  gap: 15px;
`;

export const ContactText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: var(--quarternary);
`;
