/* eslint-disable func-names */
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

Yup.setLocale({
  string: {
    email: 'E-mail inválido',
    min: ({ min }) => `O campo deve possuir no mínimo ${min} caracteres`,
    max: ({ max }) => `O campo deve possuir no máximo ${max} caracteres`,
  },
  mixed: {
    default: 'Não é válido',
    required: 'Campo obrigatório',
    notType: 'Valor inválido',
    oneOf: 'Valor inválido',
    notOneOf: 'Valor inválido',
  },
});

export interface PhoneValidProps {
  message?: string;
}

export function yupValidatePhone(
  string: Yup.StringSchema,
  { message }: PhoneValidProps = { message: 'Telefone inválido' },
) {
  const country = 'BR';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this.test({
    name: 'isValidPhone',
    message,
    test: (value: string) => isPossiblePhoneNumber(value, country) && isValidPhoneNumber(value, country),
  });
}

Yup.addMethod(Yup.string, 'isValidPhone', yupValidatePhone);

export default Yup;
