import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Path from 'react-svg-path';

export const SMALLEST_SCREEN_HEIGHT_PERCENTAGE = 1.1;
export const MOBILE_SCREEN_HEIGHT_PERCENTAGE = 1.2;
export const TABLET_SCREEN_HEIGHT_PERCENTAGE = 0.8;

const getScreenWidth = () => window.innerWidth || document.documentElement.clientWidth;
const getScreenHeight = () => window.innerHeight || document.documentElement.clientHeight;

const BlueStrip: FC = () => {
  const baseWidth = 1600;
  const baseHeight = 698;

  const isTablet = useMediaQuery({
    query: '(max-width: 1150px)',
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const isSmallestDevice = useMediaQuery({
    query: '(max-width: 375px)',
  });

  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  const DESKTOP_HEIGHT = 600;

  const getHeight = useCallback(() => {
    if (isSmallestDevice) {
      return getScreenHeight() * SMALLEST_SCREEN_HEIGHT_PERCENTAGE;
    }

    if (isMobile) {
      return getScreenHeight() * MOBILE_SCREEN_HEIGHT_PERCENTAGE;
    }

    if (isTablet) {
      return Math.min(getScreenHeight() * TABLET_SCREEN_HEIGHT_PERCENTAGE, 650 * (isRetina ? 1.2 : 1));
    }

    return DESKTOP_HEIGHT;
  }, [isMobile, isRetina, isSmallestDevice, isTablet]);

  const [currentDimensions, setCurrentDimensions] = useState<{ width: number; height: number }>({
    width: getScreenWidth(),
    height: getHeight(),
  });

  const curveFactor = useMemo(() => {
    if (isMobile) {
      return 0.2;
    }

    if (isTablet) {
      return 1;
    }

    return 2;
  }, [isMobile, isTablet]);

  const getNewAxisValue = useCallback(
    (axis: 'Y' | 'X', previousValue: number) => {
      const currentValue = axis === 'Y' ? currentDimensions.height : currentDimensions.width;
      const baseValue = axis === 'Y' ? baseHeight : baseWidth;

      return currentValue * (previousValue / baseValue);
    },
    [currentDimensions.height, currentDimensions.width],
  );

  const curvaturePercentage = 0.1;

  const topCurveHeight = currentDimensions.height * curvaturePercentage;

  const topCurve = useMemo(
    () => [
      currentDimensions.width / 2,
      topCurveHeight * curveFactor,
      getNewAxisValue('X', 1600),
      getNewAxisValue('Y', 0),
    ],
    [currentDimensions.width, getNewAxisValue, curveFactor, topCurveHeight],
  );

  const bottomCurveHeight = currentDimensions.height * (1 - curvaturePercentage * curveFactor);

  const bottomCurve = useMemo(
    () => [currentDimensions.width / 2, currentDimensions.height, getNewAxisValue('X', 0), bottomCurveHeight],
    [bottomCurveHeight, currentDimensions, getNewAxisValue],
  );

  const BackgroundPath = useCallback(() => {
    const path = new Path()
      .moveTo(0, 0)
      .Q(...topCurve)
      .V(bottomCurveHeight)
      .Q(...bottomCurve)
      .V(0)
      .close();
    return path.toComponent({ fill: 'var(--quaternary)' });
  }, [bottomCurve, bottomCurveHeight, topCurve]);

  useEffect(() => {
    setCurrentDimensions((oldState) => ({
      ...oldState,
      height: getHeight(),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHeight, isMobile, isTablet]);

  useEffect(() => {
    setCurrentDimensions((oldState) => ({
      ...oldState,
      width: getScreenWidth(),
    }));

    const onResize = () => {
      setCurrentDimensions((oldState) => ({
        ...oldState,
        width: getScreenWidth(),
      }));
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [getHeight]);

  return (
    <svg
      width={currentDimensions.width}
      height={currentDimensions.height}
      viewBox={`0 0 ${currentDimensions.width} ${currentDimensions.height}`}
      style={{
        alignSelf: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        maxWidth: '100%',
        overflow: 'hidden',
        zIndex: -1,
      }}
    >
      <BackgroundPath />
      <mask
        id="mask0_209_3"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={currentDimensions.width}
        height={currentDimensions.height}
      >
        <BackgroundPath />
      </mask>
      <g
        mask="url(#mask0_209_3)"
        style={{
          transform: `translate(${
            (currentDimensions.width - baseWidth) / 2 > 0 ? (currentDimensions.width - baseWidth) / 2 : 0
          }px)`,
        }}
      >
        <g opacity="0.05">
          <g clipPath="url(#clip0_209_3)">
            <path
              d="M18.7834 112.227C11.9393 112.227 6.36053 106.651 6.36053 99.8113C6.36053 92.9713 11.9393 87.3957 18.7834 87.3957C25.6275 87.3957 31.2064 92.9713 31.2064 99.8113C31.2064 106.651 25.6275 112.227 18.7834 112.227V112.227Z"
              fill="white"
            />
            <path
              d="M71.2357 128.034C65.1393 128.034 60.1931 123.091 60.1931 116.998C60.1931 110.905 65.1393 105.962 71.2357 105.962C77.3322 105.962 82.2783 110.905 82.2783 116.998C82.2783 123.091 77.3322 128.034 71.2357 128.034Z"
              fill="white"
            />
            <path
              d="M123.688 144.013C118.282 144.013 113.853 139.645 113.853 134.184C113.853 128.724 118.224 124.355 123.688 124.355C129.152 124.355 133.523 128.724 133.523 134.184C133.523 139.645 129.152 144.013 123.688 144.013Z"
              fill="white"
            />
            <path
              d="M176.14 159.993C171.367 159.993 167.513 156.142 167.513 151.371C167.513 146.6 171.367 142.749 176.14 142.749C180.914 142.749 184.767 146.6 184.767 151.371C184.767 156.142 180.914 159.993 176.14 159.993Z"
              fill="white"
            />
            <path
              d="M228.593 176.145C224.394 176.145 221.001 172.753 221.001 168.557C221.001 164.361 224.394 160.97 228.593 160.97C232.791 160.97 236.184 164.361 236.184 168.557C236.184 172.753 232.791 176.145 228.593 176.145Z"
              fill="white"
            />
            <path
              d="M280.987 192.354C277.364 192.354 274.431 189.422 274.431 185.801C274.431 182.18 277.364 179.248 280.987 179.248C284.611 179.248 287.544 182.18 287.544 185.801C287.544 189.422 284.611 192.354 280.987 192.354Z"
              fill="white"
            />
            <path
              d="M333.44 208.678C330.276 208.678 327.746 206.149 327.746 202.988C327.746 199.826 330.276 197.297 333.44 197.297C336.603 197.297 339.133 199.826 339.133 202.988C339.133 206.149 336.603 208.678 333.44 208.678Z"
              fill="white"
            />
            <path
              d="M385.892 225.002C383.246 225.002 381.061 222.818 381.061 220.174C381.061 217.53 383.246 215.346 385.892 215.346C388.538 215.346 390.723 217.473 390.723 220.174C390.723 222.876 388.538 225.002 385.892 225.002Z"
              fill="white"
            />
            <path
              d="M438.344 241.557C436.044 241.557 434.146 239.66 434.146 237.36C434.146 235.061 436.044 233.164 438.344 233.164C440.645 233.164 442.543 235.061 442.543 237.36C442.543 239.66 440.645 241.557 438.344 241.557Z"
              fill="white"
            />
            <path
              d="M490.797 258.513C488.611 258.513 486.828 256.731 486.828 254.547C486.828 252.363 488.611 250.581 490.797 250.581C492.982 250.581 494.765 252.363 494.765 254.547C494.765 256.731 492.982 258.513 490.797 258.513Z"
              fill="white"
            />
            <path
              d="M543.191 275.872C540.891 275.872 539.05 274.033 539.05 271.734C539.05 269.434 540.891 267.595 543.191 267.595C545.492 267.595 547.332 269.434 547.332 271.734C547.332 274.033 545.492 275.872 543.191 275.872Z"
              fill="white"
            />
            <path
              d="M595.644 293.461C593.171 293.461 591.1 291.449 591.1 288.92C591.1 286.391 593.113 284.379 595.644 284.379C598.174 284.379 600.187 286.391 600.187 288.92C600.187 291.449 598.174 293.461 595.644 293.461Z"
              fill="white"
            />
            <path
              d="M648.096 310.992C645.45 310.992 643.265 308.808 643.265 306.164C643.265 303.52 645.45 301.336 648.096 301.336C650.741 301.336 652.927 303.52 652.927 306.164C652.927 308.808 650.741 310.992 648.096 310.992Z"
              fill="white"
            />
            <path
              d="M700.548 328.408C697.73 328.408 695.487 326.167 695.487 323.35C695.487 320.534 697.73 318.292 700.548 318.292C703.366 318.292 705.609 320.534 705.609 323.35C705.609 326.167 703.366 328.408 700.548 328.408Z"
              fill="white"
            />
            <path
              d="M753 345.94C750.01 345.94 747.652 343.526 747.652 340.594C747.652 337.663 750.067 335.249 753 335.249C755.934 335.249 758.349 337.663 758.349 340.594C758.349 343.526 755.934 345.94 753 345.94Z"
              fill="white"
            />
            <path
              d="M805.453 363.874C802.059 363.874 799.299 361.114 799.299 357.723C799.299 354.332 802.059 351.573 805.453 351.573C808.846 351.573 811.607 354.332 811.607 357.723C811.607 361.114 808.846 363.874 805.453 363.874Z"
              fill="white"
            />
            <path
              d="M857.847 377.841C856.237 377.841 854.972 376.577 854.972 374.967C854.972 373.358 856.237 372.093 857.847 372.093C859.458 372.093 860.723 373.358 860.723 374.967C860.723 376.577 859.458 377.841 857.847 377.841Z"
              fill="white"
            />
            <path
              d="M910.3 395.2C908.574 395.2 907.194 393.82 907.194 392.096C907.194 390.372 908.574 388.992 910.3 388.992C912.025 388.992 913.405 390.372 913.405 392.096C913.405 393.82 912.025 395.2 910.3 395.2Z"
              fill="white"
            />
            <path
              d="M962.752 412.731C960.854 412.731 959.359 411.237 959.359 409.34C959.359 407.443 960.854 405.949 962.752 405.949C964.65 405.949 966.145 407.443 966.145 409.34C966.145 411.237 964.65 412.731 962.752 412.731Z"
              fill="white"
            />
            <path
              d="M1015.2 430.205C1013.19 430.205 1011.52 428.538 1011.52 426.527C1011.52 424.515 1013.19 422.848 1015.2 422.848C1017.22 422.848 1018.89 424.515 1018.89 426.527C1018.89 428.538 1017.22 430.205 1015.2 430.205Z"
              fill="white"
            />
            <path
              d="M1067.66 447.737C1065.47 447.737 1063.69 445.955 1063.69 443.77C1063.69 441.586 1065.47 439.804 1067.66 439.804C1069.84 439.804 1071.63 441.586 1071.63 443.77C1071.63 445.955 1069.84 447.737 1067.66 447.737Z"
              fill="white"
            />
            <path
              d="M1120.05 465.21C1117.69 465.21 1115.74 463.314 1115.74 460.899C1115.74 458.485 1117.64 456.588 1120.05 456.588C1122.47 456.588 1124.36 458.485 1124.36 460.899C1124.36 463.314 1122.47 465.21 1120.05 465.21Z"
              fill="white"
            />
            <path
              d="M1172.5 482.742C1169.97 482.742 1167.9 480.672 1167.9 478.143C1167.9 475.614 1169.97 473.545 1172.5 473.545C1175.03 473.545 1177.1 475.614 1177.1 478.143C1177.1 480.672 1175.03 482.742 1172.5 482.742Z"
              fill="white"
            />
            <path
              d="M1224.96 500.216C1222.25 500.216 1220.07 498.031 1220.07 495.33C1220.07 492.628 1222.25 490.444 1224.96 490.444C1227.66 490.444 1229.84 492.628 1229.84 495.33C1229.84 498.031 1227.66 500.216 1224.96 500.216Z"
              fill="white"
            />
            <path
              d="M1277.41 517.804C1274.47 517.804 1272.12 515.448 1272.12 512.516C1272.12 509.585 1274.47 507.228 1277.41 507.228C1280.34 507.228 1282.7 509.585 1282.7 512.516C1282.7 515.448 1280.34 517.804 1277.41 517.804Z"
              fill="white"
            />
            <path
              d="M1329.86 535.508C1326.7 535.508 1324.11 532.922 1324.11 529.76C1324.11 526.599 1326.7 524.012 1329.86 524.012C1333.02 524.012 1335.61 526.599 1335.61 529.76C1335.61 532.922 1333.02 535.508 1329.86 535.508Z"
              fill="white"
            />
            <path
              d="M1382.26 553.155C1378.8 553.155 1376.04 550.396 1376.04 546.947C1376.04 543.498 1378.8 540.739 1382.26 540.739C1385.71 540.739 1388.47 543.498 1388.47 546.947C1388.47 550.396 1385.65 553.155 1382.26 553.155Z"
              fill="white"
            />
            <path
              d="M1434.71 570.801C1431.03 570.801 1428.04 567.812 1428.04 564.133C1428.04 560.455 1431.03 557.466 1434.71 557.466C1438.39 557.466 1441.38 560.455 1441.38 564.133C1441.38 567.812 1438.39 570.801 1434.71 570.801Z"
              fill="white"
            />
            <path
              d="M1487.16 588.447C1483.19 588.447 1480.03 585.228 1480.03 581.32C1480.03 577.411 1483.25 574.192 1487.16 574.192C1491.07 574.192 1494.29 577.411 1494.29 581.32C1494.29 585.228 1491.07 588.447 1487.16 588.447Z"
              fill="white"
            />
            <path
              d="M1539.61 606.151C1535.36 606.151 1531.96 602.702 1531.96 598.506C1531.96 594.31 1535.41 590.861 1539.61 590.861C1543.81 590.861 1547.26 594.31 1547.26 598.506C1547.26 602.702 1543.81 606.151 1539.61 606.151Z"
              fill="white"
            />
            <path
              d="M1592.06 623.97C1587.46 623.97 1583.78 620.291 1583.78 615.693C1583.78 611.094 1587.46 607.415 1592.06 607.415C1596.67 607.415 1600.35 611.152 1600.35 615.693C1600.35 620.233 1596.61 623.97 1592.06 623.97Z"
              fill="white"
            />
            <path
              d="M18.7834 167.35C11.9393 167.35 6.36053 161.775 6.36053 154.934C6.36053 148.094 11.9393 142.519 18.7834 142.519C25.6275 142.519 31.2064 148.094 31.2064 154.934C31.2064 161.775 25.6275 167.35 18.7834 167.35V167.35Z"
              fill="white"
            />
            <path
              d="M71.2357 183.157C65.1393 183.157 60.1931 178.214 60.1931 172.178C60.1931 166.143 65.1393 161.142 71.2357 161.142C77.3322 161.142 82.2783 166.086 82.2783 172.178C82.2783 178.271 77.3322 183.157 71.2357 183.157Z"
              fill="white"
            />
            <path
              d="M123.688 199.136C118.282 199.136 113.911 194.768 113.911 189.365C113.911 183.962 118.282 179.593 123.688 179.593C129.094 179.593 133.465 183.962 133.465 189.365C133.465 194.768 129.094 199.136 123.688 199.136Z"
              fill="white"
            />
            <path
              d="M176.14 215.116C171.367 215.116 167.513 211.265 167.513 206.494C167.513 201.723 171.367 197.929 176.14 197.929C180.914 197.929 184.767 201.78 184.767 206.494C184.767 211.207 180.914 215.116 176.14 215.116Z"
              fill="white"
            />
            <path
              d="M228.593 231.268C224.394 231.268 221.058 227.876 221.058 223.738C221.058 219.599 224.452 216.208 228.593 216.208C232.734 216.208 236.127 219.599 236.127 223.738C236.127 227.876 232.734 231.268 228.593 231.268Z"
              fill="white"
            />
            <path
              d="M280.987 247.477C277.364 247.477 274.431 244.546 274.431 240.924C274.431 237.303 277.364 234.372 280.987 234.372C284.611 234.372 287.544 237.303 287.544 240.924C287.544 244.546 284.611 247.477 280.987 247.477Z"
              fill="white"
            />
            <path
              d="M333.44 263.801C330.276 263.801 327.746 261.272 327.746 258.111C327.746 254.949 330.276 252.42 333.44 252.42C336.603 252.42 339.133 254.949 339.133 258.111C339.133 261.272 336.603 263.801 333.44 263.801Z"
              fill="white"
            />
            <path
              d="M385.892 280.183C383.246 280.183 381.061 277.999 381.061 275.355C381.061 272.711 383.246 270.526 385.892 270.526C388.538 270.526 390.723 272.711 390.723 275.355C390.723 277.999 388.538 280.183 385.892 280.183Z"
              fill="white"
            />
            <path
              d="M438.344 296.68C440.631 296.68 442.485 294.827 442.485 292.541C442.485 290.255 440.631 288.403 438.344 288.403C436.057 288.403 434.203 290.255 434.203 292.541C434.203 294.827 436.057 296.68 438.344 296.68Z"
              fill="white"
            />
            <path
              d="M490.796 313.234C488.841 313.234 487.288 311.682 487.288 309.727C487.288 307.773 488.841 306.221 490.796 306.221C492.752 306.221 494.305 307.773 494.305 309.727C494.305 311.682 492.752 313.234 490.796 313.234Z"
              fill="white"
            />
            <path
              d="M543.191 329.903C541.523 329.903 540.201 328.581 540.201 326.914C540.201 325.247 541.523 323.925 543.191 323.925C544.859 323.925 546.182 325.247 546.182 326.914C546.182 328.581 544.859 329.903 543.191 329.903Z"
              fill="white"
            />
            <path
              d="M595.644 346.745C594.206 346.745 593.055 345.595 593.055 344.158C593.055 342.721 594.206 341.571 595.644 341.571C597.081 341.571 598.232 342.721 598.232 344.158C598.232 345.595 597.081 346.745 595.644 346.745Z"
              fill="white"
            />
            <path
              d="M648.096 363.644C649.398 363.644 650.454 362.588 650.454 361.287C650.454 359.985 649.398 358.93 648.096 358.93C646.793 358.93 645.738 359.985 645.738 361.287C645.738 362.588 646.793 363.644 648.096 363.644Z"
              fill="white"
            />
            <path
              d="M700.548 380.83C699.283 380.83 698.19 379.795 698.19 378.473C698.19 377.151 699.225 376.117 700.548 376.117C701.871 376.117 702.906 377.151 702.906 378.473C702.906 379.795 701.871 380.83 700.548 380.83Z"
              fill="white"
            />
            <path
              d="M753 398.074C751.678 398.074 750.642 396.982 750.642 395.717C750.642 394.453 751.735 393.303 753 393.303C754.266 393.303 755.416 394.395 755.416 395.717C755.416 397.039 754.323 398.074 753 398.074Z"
              fill="white"
            />
            <path
              d="M805.453 415.49C804.015 415.49 802.865 414.341 802.865 412.904C802.865 411.467 804.015 410.317 805.453 410.317C806.891 410.317 808.041 411.467 808.041 412.904C808.041 414.341 806.891 415.49 805.453 415.49Z"
              fill="white"
            />
            <path
              d="M857.847 432.907C856.295 432.907 855.087 431.642 855.087 430.148C855.087 428.653 856.352 427.331 857.847 427.331C859.343 427.331 860.608 428.596 860.608 430.148C860.608 431.7 859.343 432.907 857.847 432.907Z"
              fill="white"
            />
            <path
              d="M910.3 450.438C908.574 450.438 907.194 449.059 907.194 447.334C907.194 445.61 908.574 444.23 910.3 444.23C912.025 444.23 913.405 445.61 913.405 447.334C913.405 449.059 912.025 450.438 910.3 450.438Z"
              fill="white"
            />
            <path
              d="M962.752 467.969C960.854 467.969 959.244 466.417 959.244 464.521C959.244 462.624 960.797 461.072 962.752 461.072C964.707 461.072 966.203 462.624 966.203 464.521C966.203 466.417 964.65 467.969 962.752 467.969Z"
              fill="white"
            />
            <path
              d="M1015.2 485.501C1013.08 485.501 1011.35 483.776 1011.35 481.65C1011.35 479.523 1013.08 477.798 1015.2 477.798C1017.33 477.798 1019.06 479.523 1019.06 481.65C1019.06 483.776 1017.33 485.501 1015.2 485.501Z"
              fill="white"
            />
            <path
              d="M1067.66 503.09C1065.36 503.09 1063.46 501.193 1063.46 498.894C1063.46 496.594 1065.36 494.698 1067.66 494.698C1069.96 494.698 1071.86 496.594 1071.86 498.894C1071.86 501.193 1069.96 503.09 1067.66 503.09Z"
              fill="white"
            />
            <path
              d="M1120.05 520.621C1117.52 520.621 1115.51 518.609 1115.51 516.08C1115.51 513.551 1117.52 511.539 1120.05 511.539C1122.58 511.539 1124.59 513.551 1124.59 516.08C1124.59 518.609 1122.58 520.621 1120.05 520.621Z"
              fill="white"
            />
            <path
              d="M1172.5 538.152C1169.8 538.152 1167.61 535.968 1167.61 533.267C1167.61 530.565 1169.8 528.381 1172.5 528.381C1175.21 528.381 1177.39 530.565 1177.39 533.267C1177.39 535.968 1175.21 538.152 1172.5 538.152Z"
              fill="white"
            />
            <path
              d="M1224.96 555.741C1222.08 555.741 1219.72 553.384 1219.72 550.51C1219.72 547.636 1222.08 545.28 1224.96 545.28C1227.83 545.28 1230.19 547.636 1230.19 550.51C1230.19 553.384 1227.83 555.741 1224.96 555.741Z"
              fill="white"
            />
            <path
              d="M1277.41 573.387C1274.25 573.387 1271.66 570.801 1271.66 567.64C1271.66 564.478 1274.19 561.949 1277.41 561.949C1280.63 561.949 1283.16 564.536 1283.16 567.64C1283.16 570.743 1280.57 573.387 1277.41 573.387Z"
              fill="white"
            />
            <path
              d="M1329.86 591.149C1326.41 591.149 1323.59 588.332 1323.59 584.883C1323.59 581.435 1326.41 578.618 1329.86 578.618C1333.31 578.618 1336.13 581.435 1336.13 584.883C1336.13 588.332 1333.31 591.149 1329.86 591.149Z"
              fill="white"
            />
            <path
              d="M1382.26 608.852C1386 608.852 1389.04 605.816 1389.04 602.07C1389.04 598.324 1386 595.287 1382.26 595.287C1378.51 595.287 1375.47 598.324 1375.47 602.07C1375.47 605.816 1378.51 608.852 1382.26 608.852Z"
              fill="white"
            />
            <path
              d="M1434.71 626.614C1430.62 626.614 1427.35 623.337 1427.35 619.256C1427.35 615.175 1430.62 611.899 1434.71 611.899C1438.79 611.899 1442.07 615.175 1442.07 619.256C1442.07 623.337 1438.79 626.614 1434.71 626.614Z"
              fill="white"
            />
            <path
              d="M1487.16 644.375C1482.79 644.375 1479.28 640.811 1479.28 636.5C1479.28 632.189 1482.79 628.625 1487.16 628.625C1491.53 628.625 1495.04 632.132 1495.04 636.5C1495.04 640.869 1491.47 644.375 1487.16 644.375Z"
              fill="white"
            />
            <path
              d="M1539.61 662.194C1544.31 662.194 1548.12 658.385 1548.12 653.687C1548.12 648.988 1544.31 645.18 1539.61 645.18C1534.91 645.18 1531.1 648.988 1531.1 653.687C1531.1 658.385 1534.91 662.194 1539.61 662.194Z"
              fill="white"
            />
            <path
              d="M1592.06 680.07C1586.95 680.07 1582.86 675.931 1582.86 670.873C1582.86 665.815 1587 661.676 1592.06 661.676C1597.13 661.676 1601.27 665.815 1601.27 670.873C1601.27 675.931 1597.13 680.07 1592.06 680.07Z"
              fill="white"
            />
            <path
              d="M18.7835 221.611C12.457 221.611 7.28076 216.495 7.28076 210.115C7.28076 203.735 12.3995 198.619 18.7835 198.619C25.1674 198.619 30.2861 203.735 30.2861 210.115C30.2861 216.495 25.1674 221.611 18.7835 221.611Z"
              fill="white"
            />
            <path
              d="M71.2357 237.188C65.7719 237.188 61.3434 232.762 61.3434 227.302C61.3434 221.841 65.7719 217.415 71.2357 217.415C76.6995 217.415 81.128 221.841 81.128 227.302C81.128 232.762 76.6995 237.188 71.2357 237.188Z"
              fill="white"
            />
            <path
              d="M123.688 252.995C119.029 252.995 115.234 249.201 115.234 244.545C115.234 239.89 119.029 236.096 123.688 236.096C128.347 236.096 132.142 239.89 132.142 244.545C132.142 249.201 128.347 252.995 123.688 252.995Z"
              fill="white"
            />
            <path
              d="M176.14 268.859C172.172 268.859 169.009 265.641 169.009 261.732C169.009 257.823 172.229 254.604 176.14 254.604C180.051 254.604 183.272 257.823 183.272 261.732C183.272 265.641 180.051 268.859 176.14 268.859Z"
              fill="white"
            />
            <path
              d="M228.593 284.896C225.257 284.896 222.554 282.195 222.554 278.861C222.554 275.527 225.257 272.825 228.593 272.825C231.928 272.825 234.631 275.527 234.631 278.861C234.631 282.195 231.928 284.896 228.593 284.896Z"
              fill="white"
            />
            <path
              d="M280.987 301.048C278.284 301.048 276.041 298.864 276.041 296.105C276.041 293.346 278.227 291.162 280.987 291.162C283.748 291.162 285.934 293.346 285.934 296.105C285.934 298.864 283.748 301.048 280.987 301.048Z"
              fill="white"
            />
            <path
              d="M333.44 317.315C331.197 317.315 329.414 315.533 329.414 313.291C329.414 311.05 331.197 309.268 333.44 309.268C335.683 309.268 337.466 311.05 337.466 313.291C337.466 315.533 335.683 317.315 333.44 317.315Z"
              fill="white"
            />
            <path
              d="M385.892 333.639C384.167 333.639 382.729 332.202 382.729 330.478C382.729 328.753 384.167 327.316 385.892 327.316C387.617 327.316 389.055 328.753 389.055 330.478C389.055 332.202 387.617 333.639 385.892 333.639Z"
              fill="white"
            />
            <path
              d="M438.344 350.193C436.964 350.193 435.871 349.101 435.871 347.722C435.871 346.342 436.964 345.25 438.344 345.25C439.725 345.25 440.817 346.342 440.817 347.722C440.817 349.101 439.725 350.193 438.344 350.193Z"
              fill="white"
            />
            <path
              d="M490.796 366.92C489.704 366.92 488.784 366 488.784 364.908C488.784 363.816 489.704 362.896 490.796 362.896C491.889 362.896 492.809 363.816 492.809 364.908C492.809 366 491.889 366.92 490.796 366.92Z"
              fill="white"
            />
            <path
              d="M543.191 383.876C542.214 383.876 541.408 383.072 541.408 382.095C541.408 381.117 542.214 380.313 543.191 380.313C544.169 380.313 544.974 381.117 544.974 382.095C544.974 383.072 544.169 383.876 543.191 383.876Z"
              fill="white"
            />
            <path
              d="M595.644 401.063C594.666 401.063 593.861 400.258 593.861 399.281C593.861 398.304 594.666 397.499 595.644 397.499C596.621 397.499 597.426 398.304 597.426 399.281C597.426 400.258 596.621 401.063 595.644 401.063Z"
              fill="white"
            />
            <path
              d="M648.096 418.422C649.176 418.422 650.051 417.547 650.051 416.467C650.051 415.388 649.176 414.513 648.096 414.513C647.016 414.513 646.14 415.388 646.14 416.467C646.14 417.547 647.016 418.422 648.096 418.422Z"
              fill="white"
            />
            <path
              d="M700.548 435.896C699.34 435.896 698.305 434.919 698.305 433.654C698.305 432.39 699.283 431.412 700.548 431.412C701.813 431.412 702.791 432.39 702.791 433.654C702.791 434.919 701.813 435.896 700.548 435.896Z"
              fill="white"
            />
            <path
              d="M753 453.312C754.366 453.312 755.473 452.206 755.473 450.84C755.473 449.475 754.366 448.369 753 448.369C751.635 448.369 750.527 449.475 750.527 450.84C750.527 452.206 751.635 453.312 753 453.312Z"
              fill="white"
            />
            <path
              d="M805.453 470.671C804.015 470.671 802.807 469.464 802.807 468.027C802.807 466.59 803.957 465.383 805.453 465.383C806.948 465.383 808.098 466.59 808.098 468.027C808.098 469.464 806.948 470.671 805.453 470.671Z"
              fill="white"
            />
            <path
              d="M857.847 488.03C856.295 488.03 855.029 486.765 855.029 485.213C855.029 483.661 856.295 482.397 857.847 482.397C859.4 482.397 860.666 483.661 860.666 485.213C860.666 486.765 859.4 488.03 857.847 488.03Z"
              fill="white"
            />
            <path
              d="M910.3 505.389C908.689 505.389 907.366 504.067 907.366 502.457C907.366 500.848 908.689 499.526 910.3 499.526C911.91 499.526 913.233 500.848 913.233 502.457C913.233 504.067 911.91 505.389 910.3 505.389Z"
              fill="white"
            />
            <path
              d="M962.752 522.69C961.084 522.69 959.704 521.311 959.704 519.644C959.704 517.977 961.084 516.597 962.752 516.597C964.42 516.597 965.8 517.977 965.8 519.644C965.8 521.311 964.42 522.69 962.752 522.69Z"
              fill="white"
            />
            <path
              d="M1015.2 539.992C1013.48 539.992 1012.1 538.612 1012.1 536.888C1012.1 535.163 1013.48 533.726 1015.2 533.726C1016.93 533.726 1018.31 535.106 1018.31 536.888C1018.31 538.67 1016.93 539.992 1015.2 539.992Z"
              fill="white"
            />
            <path
              d="M1067.66 557.293C1065.87 557.293 1064.44 555.856 1064.44 554.074C1064.44 552.292 1065.87 550.855 1067.66 550.855C1069.44 550.855 1070.88 552.292 1070.88 554.074C1070.88 555.856 1069.44 557.293 1067.66 557.293Z"
              fill="white"
            />
            <path
              d="M1120.05 574.594C1118.21 574.594 1116.72 573.1 1116.72 571.261C1116.72 569.421 1118.21 567.927 1120.05 567.927C1121.89 567.927 1123.39 569.421 1123.39 571.261C1123.39 573.1 1121.89 574.594 1120.05 574.594Z"
              fill="white"
            />
            <path
              d="M1172.5 591.896C1170.61 591.896 1169.05 590.344 1169.05 588.447C1169.05 586.55 1170.61 584.998 1172.5 584.998C1174.4 584.998 1175.95 586.55 1175.95 588.447C1175.95 590.344 1174.4 591.896 1172.5 591.896Z"
              fill="white"
            />
            <path
              d="M1224.96 609.14C1223 609.14 1221.45 607.588 1221.45 605.634C1221.45 603.679 1223 602.127 1224.96 602.127C1226.91 602.127 1228.46 603.679 1228.46 605.634C1228.46 607.588 1226.91 609.14 1224.96 609.14Z"
              fill="white"
            />
            <path
              d="M1277.41 626.441C1275.4 626.441 1273.78 624.832 1273.78 622.82C1273.78 620.808 1275.4 619.199 1277.41 619.199C1279.42 619.199 1281.03 620.808 1281.03 622.82C1281.03 624.832 1279.42 626.441 1277.41 626.441Z"
              fill="white"
            />
            <path
              d="M1329.86 644.145C1327.62 644.145 1325.78 642.306 1325.78 640.064C1325.78 637.822 1327.62 635.983 1329.86 635.983C1332.1 635.983 1333.94 637.822 1333.94 640.064C1333.94 642.306 1332.1 644.145 1329.86 644.145Z"
              fill="white"
            />
            <path
              d="M1382.26 662.136C1379.55 662.136 1377.37 659.952 1377.37 657.25C1377.37 654.549 1379.55 652.365 1382.26 652.365C1384.96 652.365 1387.14 654.549 1387.14 657.25C1387.14 659.952 1384.96 662.136 1382.26 662.136Z"
              fill="white"
            />
            <path
              d="M1434.71 680.127C1431.54 680.127 1429.01 677.598 1429.01 674.437C1429.01 671.275 1431.54 668.746 1434.71 668.746C1437.87 668.746 1440.4 671.275 1440.4 674.437C1440.4 677.598 1437.87 680.127 1434.71 680.127Z"
              fill="white"
            />
            <path
              d="M1487.16 698.119C1483.59 698.119 1480.66 695.187 1480.66 691.623C1480.66 688.06 1483.59 685.128 1487.16 685.128C1490.73 685.128 1493.66 688.06 1493.66 691.623C1493.66 695.187 1490.73 698.119 1487.16 698.119Z"
              fill="white"
            />
            <path
              d="M18.7834 276.447C12.572 276.447 7.5683 271.446 7.5683 265.238C7.5683 259.03 12.572 254.03 18.7834 254.03C24.9949 254.03 29.9985 259.03 29.9985 265.238C29.9985 271.446 24.9949 276.447 18.7834 276.447V276.447Z"
              fill="white"
            />
            <path
              d="M71.2357 291.794C66.0595 291.794 61.9185 287.598 61.9185 282.482C61.9185 277.366 66.117 273.17 71.2357 273.17C76.3544 273.17 80.5529 277.366 80.5529 282.482C80.5529 287.598 76.3544 291.794 71.2357 291.794Z"
              fill="white"
            />
            <path
              d="M123.688 306.566C127.5 306.566 130.59 303.478 130.59 299.669C130.59 295.859 127.5 292.771 123.688 292.771C119.876 292.771 116.786 295.859 116.786 299.669C116.786 303.478 119.876 306.566 123.688 306.566Z"
              fill="white"
            />
            <path
              d="M176.14 321.511C173.61 321.511 171.539 319.442 171.539 316.855C171.539 314.268 173.61 312.257 176.14 312.257C178.671 312.257 180.741 314.326 180.741 316.855C180.741 319.384 178.671 321.511 176.14 321.511Z"
              fill="white"
            />
            <path
              d="M228.593 337.318C226.752 337.318 225.314 335.823 225.314 334.042C225.314 332.26 226.81 330.765 228.593 330.765C230.375 330.765 231.871 332.26 231.871 334.042C231.871 335.823 230.375 337.318 228.593 337.318Z"
              fill="white"
            />
            <path
              d="M280.987 354.332C279.262 354.332 277.882 352.952 277.882 351.228C277.882 349.503 279.262 348.124 280.987 348.124C282.713 348.124 284.093 349.503 284.093 351.228C284.093 352.952 282.713 354.332 280.987 354.332Z"
              fill="white"
            />
            <path
              d="M333.44 371.346C331.829 371.346 330.564 370.081 330.564 368.472C330.564 366.862 331.829 365.598 333.44 365.598C335.05 365.598 336.315 366.862 336.315 368.472C336.315 370.081 335.05 371.346 333.44 371.346Z"
              fill="white"
            />
            <path
              d="M385.892 388.303C384.397 388.303 383.189 387.095 383.189 385.601C383.189 384.106 384.397 382.899 385.892 382.899C387.387 382.899 388.595 384.106 388.595 385.601C388.595 387.095 387.387 388.303 385.892 388.303Z"
              fill="white"
            />
            <path
              d="M438.344 405.489C436.906 405.489 435.699 404.282 435.699 402.845C435.699 401.408 436.906 400.201 438.344 400.201C439.782 400.201 440.99 401.408 440.99 402.845C440.99 404.282 439.782 405.489 438.344 405.489Z"
              fill="white"
            />
            <path
              d="M490.796 422.675C489.359 422.675 488.208 421.526 488.208 420.031C488.208 418.537 489.359 417.445 490.796 417.445C492.234 417.445 493.442 418.594 493.442 420.031C493.442 421.468 492.292 422.675 490.796 422.675Z"
              fill="white"
            />
            <path
              d="M543.191 439.862C541.753 439.862 540.603 438.712 540.603 437.218C540.603 435.723 541.753 434.574 543.191 434.574C544.629 434.574 545.837 435.723 545.837 437.218C545.837 438.712 544.687 439.862 543.191 439.862Z"
              fill="white"
            />
            <path
              d="M595.643 457.106C594.206 457.106 592.998 455.899 592.998 454.462C592.998 453.025 594.206 451.818 595.643 451.818C597.081 451.818 598.289 453.025 598.289 454.462C598.289 455.899 597.081 457.106 595.643 457.106Z"
              fill="white"
            />
            <path
              d="M648.096 474.35C646.6 474.35 645.393 473.143 645.393 471.648C645.393 470.154 646.6 468.947 648.096 468.947C649.591 468.947 650.799 470.154 650.799 471.648C650.799 473.143 649.591 474.35 648.096 474.35Z"
              fill="white"
            />
            <path
              d="M700.548 491.536C699.053 491.536 697.787 490.329 697.787 488.835C697.787 487.34 698.995 486.133 700.548 486.133C702.101 486.133 703.309 487.34 703.309 488.835C703.309 490.329 702.101 491.536 700.548 491.536Z"
              fill="white"
            />
            <path
              d="M753 508.78C751.448 508.78 750.24 507.573 750.24 506.021C750.24 504.469 751.505 503.262 753 503.262C754.496 503.262 755.761 504.527 755.761 506.021C755.761 507.516 754.496 508.78 753 508.78Z"
              fill="white"
            />
            <path
              d="M805.453 526.024C803.9 526.024 802.634 524.76 802.634 523.208C802.634 521.656 803.9 520.391 805.453 520.391C807.005 520.391 808.271 521.656 808.271 523.208C808.271 524.76 807.005 526.024 805.453 526.024Z"
              fill="white"
            />
            <path
              d="M857.847 543.153C856.352 543.153 855.087 541.946 855.087 540.394C855.087 538.842 856.295 537.635 857.847 537.635C859.4 537.635 860.608 538.842 860.608 540.394C860.608 541.946 859.4 543.153 857.847 543.153Z"
              fill="white"
            />
            <path
              d="M910.3 560.282C908.804 560.282 907.654 559.075 907.654 557.638C907.654 556.201 908.862 554.994 910.3 554.994C911.737 554.994 912.945 556.201 912.945 557.638C912.945 559.075 911.737 560.282 910.3 560.282Z"
              fill="white"
            />
            <path
              d="M962.752 577.411C961.314 577.411 960.164 576.261 960.164 574.824C960.164 573.387 961.314 572.238 962.752 572.238C964.19 572.238 965.34 573.387 965.34 574.824C965.34 576.261 964.19 577.411 962.752 577.411Z"
              fill="white"
            />
            <path
              d="M1015.2 594.54C1013.82 594.54 1012.73 593.448 1012.73 592.068C1012.73 590.689 1013.88 589.539 1015.2 589.539C1016.53 589.539 1017.73 590.689 1017.73 592.068C1017.73 593.448 1016.58 594.54 1015.2 594.54Z"
              fill="white"
            />
            <path
              d="M1067.66 611.611C1066.33 611.611 1065.24 610.519 1065.24 609.197C1065.24 607.875 1066.33 606.783 1067.66 606.783C1068.98 606.783 1070.07 607.875 1070.07 609.197C1070.07 610.519 1068.98 611.611 1067.66 611.611Z"
              fill="white"
            />
            <path
              d="M1120.05 628.798C1118.73 628.798 1117.64 627.706 1117.64 626.384C1117.64 625.062 1118.73 623.97 1120.05 623.97C1121.37 623.97 1122.47 625.062 1122.47 626.384C1122.47 627.706 1121.37 628.798 1120.05 628.798Z"
              fill="white"
            />
            <path
              d="M1172.5 646.042C1173.84 646.042 1174.92 644.961 1174.92 643.628C1174.92 642.294 1173.84 641.214 1172.5 641.214C1171.17 641.214 1170.09 642.294 1170.09 643.628C1170.09 644.961 1171.17 646.042 1172.5 646.042Z"
              fill="white"
            />
            <path
              d="M1224.96 663.286C1223.58 663.286 1222.48 662.194 1222.48 660.814C1222.48 659.435 1223.63 658.343 1224.96 658.343C1226.28 658.343 1227.43 659.492 1227.43 660.814C1227.43 662.136 1226.34 663.286 1224.96 663.286Z"
              fill="white"
            />
            <path
              d="M1277.41 680.645C1275.91 680.645 1274.76 679.438 1274.76 678.001C1274.76 676.564 1275.97 675.356 1277.41 675.356C1278.85 675.356 1280.05 676.564 1280.05 678.001C1280.05 679.438 1278.85 680.645 1277.41 680.645Z"
              fill="white"
            />
            <path
              d="M1329.86 698.004C1328.31 698.004 1327.04 696.739 1327.04 695.187C1327.04 693.635 1328.31 692.371 1329.86 692.371C1331.41 692.371 1332.68 693.635 1332.68 695.187C1332.68 696.739 1331.41 698.004 1329.86 698.004Z"
              fill="white"
            />
            <path
              d="M18.7834 328.466C14.3549 328.466 10.7315 324.845 10.7315 320.419C10.7315 315.993 14.3549 312.372 18.7834 312.372C23.212 312.372 26.8353 315.993 26.8353 320.419C26.8353 324.845 23.212 328.466 18.7834 328.466Z"
              fill="white"
            />
            <path
              d="M71.2357 343.353C68.0725 343.353 65.5419 340.824 65.5419 337.663C65.5419 334.501 68.0725 331.972 71.2357 331.972C74.3989 331.972 76.9295 334.501 76.9295 337.663C76.9295 340.824 74.3989 343.353 71.2357 343.353Z"
              fill="white"
            />
            <path
              d="M123.688 359.505C121.1 359.505 118.972 357.378 118.972 354.792C118.972 352.205 121.1 350.078 123.688 350.078C126.276 350.078 128.404 352.148 128.404 354.792C128.404 357.436 126.334 359.505 123.688 359.505Z"
              fill="white"
            />
            <path
              d="M176.14 375.599C174.185 375.599 172.574 373.99 172.574 372.036C172.574 370.081 174.185 368.472 176.14 368.472C178.096 368.472 179.706 370.081 179.706 372.036C179.706 373.99 178.096 375.599 176.14 375.599Z"
              fill="white"
            />
            <path
              d="M228.593 392.153C226.982 392.153 225.659 390.831 225.659 389.222C225.659 387.613 226.982 386.291 228.593 386.291C230.203 386.291 231.526 387.613 231.526 389.222C231.526 390.831 230.203 392.153 228.593 392.153Z"
              fill="white"
            />
            <path
              d="M280.987 409.283C279.377 409.283 278.112 408.018 278.112 406.409C278.112 404.799 279.377 403.535 280.987 403.535C282.598 403.535 283.863 404.799 283.863 406.409C283.863 408.018 282.598 409.283 280.987 409.283Z"
              fill="white"
            />
            <path
              d="M333.44 426.411C331.887 426.411 330.621 425.147 330.621 423.595C330.621 422.043 331.887 420.778 333.44 420.778C334.992 420.778 336.258 422.043 336.258 423.595C336.258 425.147 334.992 426.411 333.44 426.411Z"
              fill="white"
            />
            <path
              d="M385.892 443.541C384.339 443.541 383.131 442.333 383.131 440.781C383.131 439.23 384.397 438.022 385.892 438.022C387.387 438.022 388.653 439.23 388.653 440.781C388.653 442.333 387.387 443.541 385.892 443.541Z"
              fill="white"
            />
            <path
              d="M438.344 460.727C436.849 460.727 435.641 459.52 435.641 458.025C435.641 456.531 436.849 455.324 438.344 455.324C439.84 455.324 441.047 456.531 441.047 458.025C441.047 459.52 439.84 460.727 438.344 460.727Z"
              fill="white"
            />
            <path
              d="M490.796 477.856C492.258 477.856 493.442 476.672 493.442 475.212C493.442 473.752 492.258 472.568 490.796 472.568C489.335 472.568 488.151 473.752 488.151 475.212C488.151 476.672 489.335 477.856 490.796 477.856Z"
              fill="white"
            />
            <path
              d="M543.191 495.042C541.753 495.042 540.546 493.835 540.546 492.398C540.546 490.961 541.753 489.754 543.191 489.754C544.629 489.754 545.837 490.961 545.837 492.398C545.837 493.835 544.629 495.042 543.191 495.042Z"
              fill="white"
            />
            <path
              d="M595.644 512.286C594.148 512.286 592.94 511.079 592.94 509.585C592.94 508.09 594.148 506.883 595.644 506.883C597.139 506.883 598.347 508.09 598.347 509.585C598.347 511.079 597.139 512.286 595.644 512.286Z"
              fill="white"
            />
            <path
              d="M648.096 529.53C646.601 529.53 645.393 528.323 645.393 526.829C645.393 525.334 646.601 524.127 648.096 524.127C649.591 524.127 650.799 525.334 650.799 526.829C650.799 528.323 649.591 529.53 648.096 529.53Z"
              fill="white"
            />
            <path
              d="M700.548 546.717C699.053 546.717 697.787 545.51 697.787 543.958C697.787 542.406 698.995 541.199 700.548 541.199C702.101 541.199 703.309 542.406 703.309 543.958C703.309 545.51 702.101 546.717 700.548 546.717Z"
              fill="white"
            />
            <path
              d="M753 563.961C751.448 563.961 750.24 562.696 750.24 561.202C750.24 559.707 751.505 558.443 753 558.443C754.496 558.443 755.761 559.707 755.761 561.202C755.761 562.696 754.496 563.961 753 563.961Z"
              fill="white"
            />
            <path
              d="M805.453 581.205C803.9 581.205 802.634 579.94 802.634 578.388C802.634 576.836 803.9 575.572 805.453 575.572C807.006 575.572 808.271 576.836 808.271 578.388C808.271 579.94 807.006 581.205 805.453 581.205Z"
              fill="white"
            />
            <path
              d="M857.847 598.276C856.352 598.276 855.144 597.069 855.144 595.575C855.144 594.08 856.352 592.873 857.847 592.873C859.343 592.873 860.551 594.08 860.551 595.575C860.551 597.069 859.343 598.276 857.847 598.276Z"
              fill="white"
            />
            <path
              d="M910.3 615.405C908.862 615.405 907.712 614.256 907.712 612.819C907.712 611.382 908.862 610.232 910.3 610.232C911.737 610.232 912.888 611.382 912.888 612.819C912.888 614.256 911.737 615.405 910.3 615.405Z"
              fill="white"
            />
            <path
              d="M962.752 632.477C961.372 632.477 960.279 631.384 960.279 630.005C960.279 628.625 961.372 627.533 962.752 627.533C964.132 627.533 965.225 628.625 965.225 630.005C965.225 631.384 964.132 632.477 962.752 632.477Z"
              fill="white"
            />
            <path
              d="M1015.2 649.606C1013.88 649.606 1012.79 648.514 1012.79 647.191C1012.79 645.869 1013.88 644.777 1015.2 644.777C1016.53 644.777 1017.62 645.869 1017.62 647.191C1017.62 648.514 1016.53 649.606 1015.2 649.606Z"
              fill="white"
            />
            <path
              d="M1067.66 666.735C1066.33 666.735 1065.3 665.7 1065.3 664.378C1065.3 663.056 1066.39 662.021 1067.66 662.021C1068.92 662.021 1070.01 663.113 1070.01 664.378C1070.01 665.642 1068.92 666.735 1067.66 666.735Z"
              fill="white"
            />
            <path
              d="M1120.05 684.151C1118.61 684.151 1117.46 683.001 1117.46 681.564C1117.46 680.127 1118.61 678.978 1120.05 678.978C1121.49 678.978 1122.64 680.127 1122.64 681.564C1122.64 683.001 1121.49 684.151 1120.05 684.151Z"
              fill="white"
            />
            <path
              d="M1172.5 701.625C1170.89 701.625 1169.63 700.36 1169.63 698.751C1169.63 697.141 1170.89 695.877 1172.5 695.877C1174.11 695.877 1175.38 697.141 1175.38 698.751C1175.38 700.36 1174.11 701.625 1172.5 701.625Z"
              fill="white"
            />
            <path
              d="M18.7834 381.347C21.9598 381.347 24.5348 378.774 24.5348 375.599C24.5348 372.425 21.9598 369.851 18.7834 369.851C15.607 369.851 13.0321 372.425 13.0321 375.599C13.0321 378.774 15.607 381.347 18.7834 381.347Z"
              fill="white"
            />
            <path
              d="M71.2357 397.499C68.6476 397.499 66.5196 395.372 66.5196 392.786C66.5196 390.199 68.6476 388.073 71.2357 388.073C73.8238 388.073 75.9518 390.199 75.9518 392.786C75.9518 395.372 73.8238 397.499 71.2357 397.499Z"
              fill="white"
            />
            <path
              d="M123.688 413.306C121.848 413.306 120.352 411.812 120.352 409.972C120.352 408.133 121.848 406.638 123.688 406.638C125.528 406.638 127.024 408.133 127.024 409.972C127.024 411.812 125.528 413.306 123.688 413.306Z"
              fill="white"
            />
            <path
              d="M176.14 430.435C174.357 430.435 172.862 428.998 172.862 427.159C172.862 425.319 174.3 423.882 176.14 423.882C177.981 423.882 179.419 425.319 179.419 427.159C179.419 428.998 177.981 430.435 176.14 430.435Z"
              fill="white"
            />
            <path
              d="M228.593 447.564C226.81 447.564 225.372 446.127 225.372 444.345C225.372 442.563 226.81 441.126 228.593 441.126C230.375 441.126 231.813 442.563 231.813 444.345C231.813 446.127 230.375 447.564 228.593 447.564Z"
              fill="white"
            />
            <path
              d="M280.987 464.693C279.262 464.693 277.882 463.314 277.882 461.589C277.882 459.865 279.262 458.485 280.987 458.485C282.713 458.485 284.093 459.865 284.093 461.589C284.093 463.314 282.713 464.693 280.987 464.693Z"
              fill="white"
            />
            <path
              d="M333.44 481.822C331.772 481.822 330.391 480.443 330.391 478.776C330.391 477.109 331.772 475.729 333.44 475.729C335.108 475.729 336.488 477.109 336.488 478.776C336.488 480.443 335.108 481.822 333.44 481.822Z"
              fill="white"
            />
            <path
              d="M385.892 498.951C384.224 498.951 382.901 497.629 382.901 495.962C382.901 494.295 384.224 492.973 385.892 492.973C387.56 492.973 388.883 494.295 388.883 495.962C388.883 497.629 387.56 498.951 385.892 498.951Z"
              fill="white"
            />
            <path
              d="M438.344 516.08C436.734 516.08 435.468 514.815 435.468 513.206C435.468 511.597 436.791 510.332 438.344 510.332C439.897 510.332 441.22 511.654 441.22 513.206C441.22 514.758 439.897 516.08 438.344 516.08Z"
              fill="white"
            />
            <path
              d="M490.796 533.209C489.244 533.209 487.921 531.944 487.921 530.335C487.921 528.726 489.186 527.461 490.796 527.461C492.407 527.461 493.672 528.726 493.672 530.335C493.672 531.944 492.407 533.209 490.796 533.209Z"
              fill="white"
            />
            <path
              d="M543.191 550.395C541.638 550.395 540.373 549.131 540.373 547.521C540.373 545.912 541.638 544.705 543.191 544.705C544.744 544.705 546.009 545.97 546.009 547.521C546.009 549.073 544.744 550.395 543.191 550.395Z"
              fill="white"
            />
            <path
              d="M595.644 567.582C594.091 567.582 592.825 566.317 592.825 564.765C592.825 563.213 594.091 561.949 595.644 561.949C597.196 561.949 598.462 563.213 598.462 564.765C598.462 566.317 597.196 567.582 595.644 567.582Z"
              fill="white"
            />
            <path
              d="M648.096 584.768C646.543 584.768 645.278 583.504 645.278 581.952C645.278 580.4 646.543 579.135 648.096 579.135C649.649 579.135 650.914 580.4 650.914 581.952C650.914 583.504 649.649 584.768 648.096 584.768Z"
              fill="white"
            />
            <path
              d="M700.548 601.955C698.995 601.955 697.73 600.69 697.73 599.138C697.73 597.586 698.995 596.322 700.548 596.322C702.101 596.322 703.366 597.586 703.366 599.138C703.366 600.69 702.101 601.955 700.548 601.955Z"
              fill="white"
            />
            <path
              d="M753 619.199C751.448 619.199 750.182 617.934 750.182 616.382C750.182 614.83 751.448 613.566 753 613.566C754.553 613.566 755.819 614.83 755.819 616.382C755.819 617.934 754.553 619.199 753 619.199Z"
              fill="white"
            />
            <path
              d="M805.453 636.385C803.9 636.385 802.634 635.121 802.634 633.569C802.634 632.017 803.9 630.752 805.453 630.752C807.005 630.752 808.271 632.017 808.271 633.569C808.271 635.121 807.005 636.385 805.453 636.385Z"
              fill="white"
            />
            <path
              d="M857.847 653.457C856.352 653.457 855.144 652.25 855.144 650.755C855.144 649.261 856.352 648.054 857.847 648.054C859.343 648.054 860.55 649.261 860.55 650.755C860.55 652.25 859.343 653.457 857.847 653.457Z"
              fill="white"
            />
            <path
              d="M910.3 670.528C908.862 670.528 907.712 669.379 907.712 667.942C907.712 666.505 908.862 665.355 910.3 665.355C911.737 665.355 912.888 666.505 912.888 667.942C912.888 669.379 911.737 670.528 910.3 670.528Z"
              fill="white"
            />
            <path
              d="M962.752 687.6C961.372 687.6 960.279 686.508 960.279 685.128C960.279 683.749 961.372 682.656 962.752 682.656C964.132 682.656 965.225 683.749 965.225 685.128C965.225 686.508 964.132 687.6 962.752 687.6Z"
              fill="white"
            />
            <path
              d="M18.7834 435.781C16.0228 435.781 13.7223 433.539 13.7223 430.722C13.7223 427.906 15.9653 425.664 18.7834 425.664C21.6016 425.664 23.8446 427.906 23.8446 430.722C23.8446 433.539 21.6016 435.781 18.7834 435.781Z"
              fill="white"
            />
            <path
              d="M71.2357 452.22C68.8777 452.22 66.9797 450.323 66.9797 447.966C66.9797 445.61 68.8777 443.713 71.2357 443.713C73.5938 443.713 75.4917 445.61 75.4917 447.966C75.4917 450.323 73.5938 452.22 71.2357 452.22Z"
              fill="white"
            />
            <path
              d="M123.688 469.062C121.503 469.062 119.777 467.28 119.777 465.153C119.777 463.026 121.56 461.244 123.688 461.244C125.816 461.244 127.599 463.026 127.599 465.153C127.599 467.28 125.816 469.062 123.688 469.062Z"
              fill="white"
            />
            <path
              d="M176.14 485.903C178.11 485.903 179.706 484.308 179.706 482.339C179.706 480.371 178.11 478.776 176.14 478.776C174.171 478.776 172.574 480.371 172.574 482.339C172.574 484.308 174.171 485.903 176.14 485.903Z"
              fill="white"
            />
            <path
              d="M228.593 502.802C226.81 502.802 225.372 501.365 225.372 499.583C225.372 497.802 226.81 496.307 228.593 496.307C230.376 496.307 231.813 497.744 231.813 499.583C231.813 501.423 230.376 502.802 228.593 502.802Z"
              fill="white"
            />
            <path
              d="M280.987 519.701C279.377 519.701 278.054 518.379 278.054 516.77C278.054 515.16 279.377 513.838 280.987 513.838C282.598 513.838 283.921 515.16 283.921 516.77C283.921 518.379 282.598 519.701 280.987 519.701Z"
              fill="white"
            />
            <path
              d="M333.44 536.773C331.829 536.773 330.564 535.508 330.564 533.899C330.564 532.289 331.829 531.025 333.44 531.025C335.05 531.025 336.315 532.289 336.315 533.899C336.315 535.508 335.05 536.773 333.44 536.773Z"
              fill="white"
            />
            <path
              d="M385.892 553.902C384.339 553.902 383.131 552.637 383.131 551.143C383.131 549.648 384.397 548.384 385.892 548.384C387.387 548.384 388.653 549.648 388.653 551.143C388.653 552.637 387.387 553.902 385.892 553.902Z"
              fill="white"
            />
            <path
              d="M438.344 571.031C436.849 571.031 435.641 569.824 435.641 568.329C435.641 566.835 436.849 565.628 438.344 565.628C439.84 565.628 441.047 566.835 441.047 568.329C441.047 569.824 439.84 571.031 438.344 571.031Z"
              fill="white"
            />
            <path
              d="M490.796 588.275C489.301 588.275 488.093 587.068 488.093 585.573C488.093 584.079 489.301 582.872 490.796 582.872C492.292 582.872 493.5 584.079 493.5 585.573C493.5 587.068 492.292 588.275 490.796 588.275Z"
              fill="white"
            />
            <path
              d="M543.191 605.519C541.638 605.519 540.431 604.254 540.431 602.76C540.431 601.265 541.696 600 543.191 600C544.687 600 545.952 601.265 545.952 602.76C545.952 604.254 544.687 605.519 543.191 605.519Z"
              fill="white"
            />
            <path
              d="M595.644 622.705C594.091 622.705 592.825 621.441 592.825 619.889C592.825 618.337 594.091 617.072 595.644 617.072C597.196 617.072 598.462 618.337 598.462 619.889C598.462 621.441 597.196 622.705 595.644 622.705Z"
              fill="white"
            />
            <path
              d="M648.096 639.949C646.543 639.949 645.278 638.684 645.278 637.132C645.278 635.581 646.543 634.316 648.096 634.316C649.649 634.316 650.914 635.581 650.914 637.132C650.914 638.684 649.649 639.949 648.096 639.949Z"
              fill="white"
            />
            <path
              d="M700.548 657.135C698.995 657.135 697.73 655.871 697.73 654.319C697.73 652.767 698.995 651.502 700.548 651.502C702.101 651.502 703.366 652.767 703.366 654.319C703.366 655.871 702.101 657.135 700.548 657.135Z"
              fill="white"
            />
            <path
              d="M753 674.322C751.448 674.322 750.182 673.057 750.182 671.505C750.182 669.953 751.448 668.689 753 668.689C754.553 668.689 755.819 669.953 755.819 671.505C755.819 673.057 754.553 674.322 753 674.322Z"
              fill="white"
            />
            <path
              d="M805.453 691.508C803.9 691.508 802.635 690.244 802.635 688.692C802.635 687.14 803.9 685.875 805.453 685.875C807.006 685.875 808.271 687.14 808.271 688.692C808.271 690.244 807.006 691.508 805.453 691.508Z"
              fill="white"
            />
            <path
              d="M18.7834 489.639C16.7129 489.639 15.045 487.972 15.045 485.903C15.045 483.834 16.7129 482.167 18.7834 482.167C20.8539 482.167 22.5218 483.834 22.5218 485.903C22.5218 487.972 20.8539 489.639 18.7834 489.639Z"
              fill="white"
            />
            <path
              d="M71.2357 506.653C69.2802 506.653 67.6699 505.044 67.6699 503.09C67.6699 501.135 69.2802 499.526 71.2357 499.526C73.1912 499.526 74.8016 501.135 74.8016 503.09C74.8016 505.044 73.1912 506.653 71.2357 506.653Z"
              fill="white"
            />
            <path
              d="M123.688 523.667C121.848 523.667 120.352 522.173 120.352 520.334C120.352 518.494 121.848 516.942 123.688 516.942C125.528 516.942 127.024 518.437 127.024 520.334C127.024 522.23 125.528 523.667 123.688 523.667Z"
              fill="white"
            />
            <path
              d="M176.14 540.796C174.3 540.796 172.862 539.302 172.862 537.52C172.862 535.738 174.357 534.244 176.14 534.244C177.923 534.244 179.419 535.738 179.419 537.52C179.419 539.302 177.923 540.796 176.14 540.796Z"
              fill="white"
            />
            <path
              d="M228.593 557.925C226.81 557.925 225.372 556.488 225.372 554.706C225.372 552.925 226.81 551.488 228.593 551.488C230.376 551.488 231.813 552.925 231.813 554.706C231.813 556.488 230.376 557.925 228.593 557.925Z"
              fill="white"
            />
            <path
              d="M280.987 575.054C279.262 575.054 277.824 573.675 277.824 571.893C277.824 570.111 279.204 568.731 280.987 568.731C282.77 568.731 284.151 570.168 284.151 571.893C284.151 573.617 282.77 575.054 280.987 575.054Z"
              fill="white"
            />
            <path
              d="M333.44 592.183C331.714 592.183 330.391 590.804 330.391 589.137C330.391 587.47 331.772 586.09 333.44 586.09C335.107 586.09 336.488 587.47 336.488 589.137C336.488 590.804 335.107 592.183 333.44 592.183Z"
              fill="white"
            />
            <path
              d="M385.892 609.312C384.224 609.312 382.901 607.99 382.901 606.323C382.901 604.656 384.224 603.334 385.892 603.334C387.56 603.334 388.883 604.656 388.883 606.323C388.883 607.99 387.56 609.312 385.892 609.312Z"
              fill="white"
            />
            <path
              d="M438.344 626.441C436.734 626.441 435.411 625.119 435.411 623.51C435.411 621.9 436.734 620.578 438.344 620.578C439.955 620.578 441.277 621.9 441.277 623.51C441.277 625.119 439.955 626.441 438.344 626.441Z"
              fill="white"
            />
            <path
              d="M490.797 643.57C489.186 643.57 487.921 642.248 487.921 640.696C487.921 639.144 489.186 637.822 490.797 637.822C492.407 637.822 493.672 639.144 493.672 640.696C493.672 642.248 492.407 643.57 490.797 643.57Z"
              fill="white"
            />
            <path
              d="M543.191 660.757C541.581 660.757 540.316 659.492 540.316 657.883C540.316 656.273 541.581 655.009 543.191 655.009C544.802 655.009 546.067 656.273 546.067 657.883C546.067 659.492 544.802 660.757 543.191 660.757Z"
              fill="white"
            />
            <path
              d="M595.644 677.943C594.091 677.943 592.768 676.679 592.768 675.069C592.768 673.46 594.033 672.195 595.644 672.195C597.254 672.195 598.519 673.46 598.519 675.069C598.519 676.679 597.254 677.943 595.644 677.943Z"
              fill="white"
            />
            <path
              d="M648.096 695.13C649.684 695.13 650.971 693.843 650.971 692.256C650.971 690.668 649.684 689.382 648.096 689.382C646.508 689.382 645.22 690.668 645.22 692.256C645.22 693.843 646.508 695.13 648.096 695.13Z"
              fill="white"
            />
            <path
              d="M18.7834 543.9C17.2306 543.9 15.9653 542.636 15.9653 541.084C15.9653 539.532 17.2306 538.267 18.7834 538.267C20.3363 538.267 21.6016 539.532 21.6016 541.084C21.6016 542.636 20.3363 543.9 18.7834 543.9Z"
              fill="white"
            />
            <path
              d="M71.2357 561.087C69.6828 561.087 68.4176 559.822 68.4176 558.27C68.4176 556.718 69.6828 555.454 71.2357 555.454C72.7886 555.454 74.0539 556.718 74.0539 558.27C74.0539 559.822 72.7886 561.087 71.2357 561.087Z"
              fill="white"
            />
            <path
              d="M123.688 578.273C122.135 578.273 120.87 577.009 120.87 575.457C120.87 573.905 122.135 572.64 123.688 572.64C125.241 572.64 126.506 573.905 126.506 575.457C126.506 577.009 125.241 578.273 123.688 578.273Z"
              fill="white"
            />
            <path
              d="M176.14 595.46C174.587 595.46 173.322 594.195 173.322 592.643C173.322 591.091 174.587 589.827 176.14 589.827C177.693 589.827 178.958 591.091 178.958 592.643C178.958 594.195 177.693 595.46 176.14 595.46Z"
              fill="white"
            />
            <path
              d="M228.593 612.646C227.04 612.646 225.774 611.382 225.774 609.83C225.774 608.278 227.04 607.013 228.593 607.013C230.145 607.013 231.411 608.278 231.411 609.83C231.411 611.382 230.145 612.646 228.593 612.646Z"
              fill="white"
            />
            <path
              d="M280.987 629.89C279.434 629.89 278.169 628.625 278.169 627.073C278.169 625.522 279.434 624.257 280.987 624.257C282.54 624.257 283.805 625.522 283.805 627.073C283.805 628.625 282.54 629.89 280.987 629.89Z"
              fill="white"
            />
            <path
              d="M333.44 647.076C331.887 647.076 330.621 645.812 330.621 644.26C330.621 642.708 331.887 641.443 333.44 641.443C334.992 641.443 336.258 642.708 336.258 644.26C336.258 645.812 334.992 647.076 333.44 647.076Z"
              fill="white"
            />
            <path
              d="M385.892 664.263C384.339 664.263 383.074 662.998 383.074 661.446C383.074 659.895 384.339 658.63 385.892 658.63C387.445 658.63 388.71 659.895 388.71 661.446C388.71 662.998 387.445 664.263 385.892 664.263Z"
              fill="white"
            />
            <path
              d="M438.344 681.449C436.791 681.449 435.526 680.185 435.526 678.633C435.526 677.081 436.791 675.816 438.344 675.816C439.897 675.816 441.162 677.081 441.162 678.633C441.162 680.185 439.897 681.449 438.344 681.449Z"
              fill="white"
            />
            <path
              d="M490.796 698.636C489.244 698.636 487.978 697.371 487.978 695.819C487.978 694.267 489.244 693.003 490.796 693.003C492.349 693.003 493.615 694.267 493.615 695.819C493.615 697.371 492.349 698.636 490.796 698.636Z"
              fill="white"
            />
            <path
              d="M18.7834 599.023C17.2306 599.023 15.9653 597.759 15.9653 596.207C15.9653 594.655 17.2306 593.39 18.7834 593.39C20.3363 593.39 21.6016 594.655 21.6016 596.207C21.6016 597.759 20.3363 599.023 18.7834 599.023Z"
              fill="white"
            />
            <path
              d="M71.2357 616.21C69.6828 616.21 68.4176 614.945 68.4176 613.393C68.4176 611.841 69.6828 610.577 71.2357 610.577C72.7886 610.577 74.0539 611.841 74.0539 613.393C74.0539 614.945 72.7886 616.21 71.2357 616.21Z"
              fill="white"
            />
            <path
              d="M123.688 633.454C122.135 633.454 120.87 632.189 120.87 630.637C120.87 629.085 122.135 627.821 123.688 627.821C125.241 627.821 126.506 629.085 126.506 630.637C126.506 632.189 125.241 633.454 123.688 633.454Z"
              fill="white"
            />
            <path
              d="M176.14 650.64C174.587 650.64 173.322 649.376 173.322 647.824C173.322 646.272 174.587 645.007 176.14 645.007C177.693 645.007 178.958 646.272 178.958 647.824C178.958 649.376 177.693 650.64 176.14 650.64Z"
              fill="white"
            />
            <path
              d="M228.593 667.827C227.04 667.827 225.774 666.562 225.774 665.01C225.774 663.458 227.04 662.194 228.593 662.194C230.145 662.194 231.411 663.458 231.411 665.01C231.411 666.562 230.145 667.827 228.593 667.827Z"
              fill="white"
            />
            <path
              d="M280.987 685.013C279.434 685.013 278.169 683.749 278.169 682.197C278.169 680.645 279.434 679.38 280.987 679.38C282.54 679.38 283.805 680.645 283.805 682.197C283.805 683.749 282.54 685.013 280.987 685.013Z"
              fill="white"
            />
            <path
              d="M333.44 702.2C331.887 702.2 330.621 700.935 330.621 699.383C330.621 697.831 331.887 696.567 333.44 696.567C334.992 696.567 336.258 697.831 336.258 699.383C336.258 700.935 334.992 702.2 333.44 702.2Z"
              fill="white"
            />
            <path
              d="M18.7834 654.204C17.2306 654.204 15.9653 652.939 15.9653 651.387C15.9653 649.836 17.2306 648.571 18.7834 648.571C20.3363 648.571 21.6016 649.836 21.6016 651.387C21.6016 652.939 20.3363 654.204 18.7834 654.204Z"
              fill="white"
            />
            <path
              d="M71.2357 671.391C69.6828 671.391 68.4176 670.126 68.4176 668.574C68.4176 667.022 69.6828 665.757 71.2357 665.757C72.7886 665.757 74.0539 667.022 74.0539 668.574C74.0539 670.126 72.7886 671.391 71.2357 671.391Z"
              fill="white"
            />
            <path
              d="M123.688 688.577C122.135 688.577 120.87 687.312 120.87 685.76C120.87 684.208 122.135 682.944 123.688 682.944C125.241 682.944 126.506 684.208 126.506 685.76C126.506 687.312 125.241 688.577 123.688 688.577Z"
              fill="white"
            />
            <path
              d="M1382.26 1.75083C1378.69 1.75083 1375.81 -1.12313 1375.81 -4.68688C1375.81 -8.25063 1378.69 -11.1246 1382.26 -11.1246C1385.82 -11.1246 1388.7 -8.25063 1388.7 -4.68688C1388.7 -1.12313 1385.82 1.75083 1382.26 1.75083Z"
              fill="white"
            />
            <path
              d="M1434.71 18.9372C1431.14 18.9372 1428.27 16.0633 1428.27 12.4995C1428.27 8.93577 1431.14 6.06177 1434.71 6.06177C1438.27 6.06177 1441.15 8.93577 1441.15 12.4995C1441.15 16.0633 1438.27 18.9372 1434.71 18.9372Z"
              fill="white"
            />
            <path
              d="M1487.16 36.1237C1483.59 36.1237 1480.72 33.2497 1480.72 29.686C1480.72 26.1222 1483.59 23.2482 1487.16 23.2482C1490.73 23.2482 1493.6 26.1222 1493.6 29.686C1493.6 33.2497 1490.73 36.1237 1487.16 36.1237Z"
              fill="white"
            />
            <path
              d="M1539.61 53.3676C1536.05 53.3676 1533.17 50.4936 1533.17 46.9299C1533.17 43.3661 1536.05 40.4921 1539.61 40.4921C1543.18 40.4921 1546.05 43.3661 1546.05 46.9299C1546.05 50.4936 1543.18 53.3676 1539.61 53.3676Z"
              fill="white"
            />
            <path
              d="M1592.06 70.5541C1588.5 70.5541 1585.62 67.6801 1585.62 64.1164C1585.62 60.5526 1588.5 57.6786 1592.06 57.6786C1595.63 57.6786 1598.51 60.5526 1598.51 64.1164C1598.51 67.6801 1595.63 70.5541 1592.06 70.5541Z"
              fill="white"
            />
            <path
              d="M1592.06 15.3735C1588.5 15.3735 1585.62 12.4995 1585.62 8.93576C1585.62 5.37201 1588.5 2.49805 1592.06 2.49805C1595.63 2.49805 1598.51 5.37201 1598.51 8.93576C1598.51 12.4995 1595.63 15.3735 1592.06 15.3735Z"
              fill="white"
            />
            <path
              d="M1224.96 1.6933C1223.4 1.6933 1222.14 0.428755 1222.14 -1.1232C1222.14 -2.67515 1223.4 -3.9397 1224.96 -3.9397C1226.51 -3.9397 1227.77 -2.67515 1227.77 -1.1232C1227.77 0.428755 1226.51 1.6933 1224.96 1.6933Z"
              fill="white"
            />
            <path
              d="M1277.41 19.1097C1275.74 19.1097 1274.36 17.7302 1274.36 16.0633C1274.36 14.3964 1275.74 13.0168 1277.41 13.0168C1279.08 13.0168 1280.46 14.3964 1280.46 16.0633C1280.46 17.7302 1279.08 19.1097 1277.41 19.1097Z"
              fill="white"
            />
            <path
              d="M1329.86 36.641C1328.02 36.641 1326.52 35.1465 1326.52 33.3072C1326.52 31.4678 1328.02 29.9734 1329.86 29.9734C1331.7 29.9734 1333.2 31.4678 1333.2 33.3072C1333.2 35.1465 1331.7 36.641 1329.86 36.641Z"
              fill="white"
            />
            <path
              d="M1382.26 54.2299C1380.18 54.2299 1378.46 52.563 1378.46 50.4362C1378.46 48.3095 1380.13 46.6426 1382.26 46.6426C1384.38 46.6426 1386.05 48.3095 1386.05 50.4362C1386.05 52.563 1384.38 54.2299 1382.26 54.2299Z"
              fill="white"
            />
            <path
              d="M1434.71 71.9336C1432.35 71.9336 1430.39 70.0368 1430.39 67.6801C1430.39 65.3235 1432.29 63.4266 1434.71 63.4266C1437.12 63.4266 1438.96 65.3235 1438.96 67.6801C1438.96 70.0368 1437.07 71.9336 1434.71 71.9336Z"
              fill="white"
            />
            <path
              d="M1487.16 89.7524C1484.46 89.7524 1482.27 87.5682 1482.27 84.8666C1482.27 82.1651 1484.46 79.9808 1487.16 79.9808C1489.86 79.9808 1492.05 82.1651 1492.05 84.8666C1492.05 87.5682 1489.86 89.7524 1487.16 89.7524Z"
              fill="white"
            />
            <path
              d="M1539.61 107.686C1536.51 107.686 1533.98 105.157 1533.98 102.053C1533.98 98.9492 1536.51 96.42 1539.61 96.42C1542.72 96.42 1545.25 98.9492 1545.25 102.053C1545.25 105.157 1542.72 107.686 1539.61 107.686Z"
              fill="white"
            />
            <path
              d="M1592.06 125.792C1588.5 125.792 1585.57 122.861 1585.57 119.297C1585.57 115.733 1588.5 112.802 1592.06 112.802C1595.63 112.802 1598.56 115.733 1598.56 119.297C1598.56 122.861 1595.63 125.792 1592.06 125.792Z"
              fill="white"
            />
            <path
              d="M1067.66 5.372C1066.05 5.372 1064.72 4.04995 1064.72 2.44052C1064.72 0.831081 1066.05 -0.490967 1067.66 -0.490967C1069.27 -0.490967 1070.59 0.831081 1070.59 2.44052C1070.59 4.04995 1069.27 5.372 1067.66 5.372Z"
              fill="white"
            />
            <path
              d="M1120.05 22.4435C1118.5 22.4435 1117.23 21.179 1117.23 19.627C1117.23 18.0751 1118.5 16.8105 1120.05 16.8105C1121.6 16.8105 1122.87 18.0751 1122.87 19.627C1122.87 21.179 1121.6 22.4435 1120.05 22.4435Z"
              fill="white"
            />
            <path
              d="M1172.5 39.9174C1170.78 39.9174 1169.4 38.5379 1169.4 36.8135C1169.4 35.0891 1170.78 33.7096 1172.5 33.7096C1174.23 33.7096 1175.61 35.0891 1175.61 36.8135C1175.61 38.5379 1174.23 39.9174 1172.5 39.9174Z"
              fill="white"
            />
            <path
              d="M1224.96 57.3913C1223.12 57.3913 1221.62 55.8968 1221.62 54.0574C1221.62 52.2181 1223.12 50.7236 1224.96 50.7236C1226.8 50.7236 1228.29 52.2181 1228.29 54.0574C1228.29 55.8968 1226.8 57.3913 1224.96 57.3913Z"
              fill="white"
            />
            <path
              d="M1277.41 74.9226C1279.44 74.9226 1281.09 73.2756 1281.09 71.2439C1281.09 69.2122 1279.44 67.5652 1277.41 67.5652C1275.38 67.5652 1273.73 69.2122 1273.73 71.2439C1273.73 73.2756 1275.38 74.9226 1277.41 74.9226Z"
              fill="white"
            />
            <path
              d="M1329.86 92.5114C1327.62 92.5114 1325.78 90.672 1325.78 88.4303C1325.78 86.1886 1327.62 84.3492 1329.86 84.3492C1332.1 84.3492 1333.94 86.1886 1333.94 88.4303C1333.94 90.672 1332.1 92.5114 1329.86 92.5114Z"
              fill="white"
            />
            <path
              d="M1382.26 110.158C1379.72 110.158 1377.71 108.146 1377.71 105.617C1377.71 103.088 1379.72 101.076 1382.26 101.076C1384.79 101.076 1386.8 103.088 1386.8 105.617C1386.8 108.146 1384.79 110.158 1382.26 110.158Z"
              fill="white"
            />
            <path
              d="M1434.71 127.919C1431.89 127.919 1429.65 125.62 1429.65 122.861C1429.65 120.102 1431.95 117.802 1434.71 117.802C1437.47 117.802 1439.77 120.102 1439.77 122.861C1439.77 125.62 1437.47 127.919 1434.71 127.919Z"
              fill="white"
            />
            <path
              d="M1487.16 145.68C1484.05 145.68 1481.47 143.151 1481.47 140.047C1481.47 136.943 1484 134.357 1487.16 134.357C1490.32 134.357 1492.85 136.886 1492.85 140.047C1492.85 143.209 1490.32 145.68 1487.16 145.68Z"
              fill="white"
            />
            <path
              d="M1539.61 163.614C1536.1 163.614 1533.23 160.74 1533.23 157.234C1533.23 153.727 1536.1 150.853 1539.61 150.853C1543.12 150.853 1546 153.727 1546 157.234C1546 160.74 1543.12 163.614 1539.61 163.614Z"
              fill="white"
            />
            <path
              d="M1592.06 181.548C1588.15 181.548 1584.93 178.386 1584.93 174.42C1584.93 170.454 1588.1 167.293 1592.06 167.293C1596.03 167.293 1599.2 170.454 1599.2 174.42C1599.2 178.386 1596.03 181.548 1592.06 181.548Z"
              fill="white"
            />
            <path
              d="M917.42 6.06786C917.428 2.1632 914.268 -1.00884 910.361 -1.01701C906.454 -1.02519 903.28 2.13359 903.272 6.03824C903.264 9.94289 906.424 13.1148 910.331 13.123C914.238 13.1312 917.412 9.97251 917.42 6.06786Z"
              fill="white"
            />
            <path
              d="M962.752 27.5018C960.394 27.5018 958.439 25.605 958.439 23.1908C958.439 20.7767 960.337 18.8798 962.752 18.8798C965.168 18.8798 967.066 20.7767 967.066 23.1908C967.066 25.605 965.168 27.5018 962.752 27.5018Z"
              fill="white"
            />
            <path
              d="M1015.2 43.3087C1013.59 43.3087 1012.33 41.9867 1012.33 40.4347C1012.33 38.8828 1013.59 37.5607 1015.2 37.5607C1016.81 37.5607 1018.08 38.8253 1018.08 40.4347C1018.08 42.0442 1016.81 43.3087 1015.2 43.3087Z"
              fill="white"
            />
            <path
              d="M1067.66 60.4377C1066.1 60.4377 1064.84 59.1731 1064.84 57.6212C1064.84 56.0692 1066.1 54.8047 1067.66 54.8047C1069.21 54.8047 1070.47 56.0692 1070.47 57.6212C1070.47 59.1731 1069.21 60.4377 1067.66 60.4377Z"
              fill="white"
            />
            <path
              d="M1120.05 77.6816C1118.5 77.6816 1117.18 76.417 1117.18 74.8076C1117.18 73.1981 1118.44 71.9336 1120.05 71.9336C1121.66 71.9336 1122.93 73.1981 1122.93 74.8076C1122.93 76.417 1121.66 77.6816 1120.05 77.6816Z"
              fill="white"
            />
            <path
              d="M1172.5 94.8681C1170.89 94.8681 1169.63 93.6035 1169.63 91.9941C1169.63 90.3847 1170.89 89.1201 1172.5 89.1201C1174.11 89.1201 1175.38 90.3847 1175.38 91.9941C1175.38 93.6035 1174.11 94.8681 1172.5 94.8681Z"
              fill="white"
            />
            <path
              d="M1224.96 112.227C1223.29 112.227 1221.97 110.905 1221.97 109.238C1221.97 107.571 1223.35 106.249 1224.96 106.249C1226.57 106.249 1227.95 107.571 1227.95 109.238C1227.95 110.905 1226.62 112.227 1224.96 112.227Z"
              fill="white"
            />
            <path
              d="M1277.41 129.643C1275.63 129.643 1274.19 128.206 1274.19 126.425C1274.19 124.643 1275.63 123.206 1277.41 123.206C1279.19 123.206 1280.63 124.643 1280.63 126.425C1280.63 128.206 1279.19 129.643 1277.41 129.643Z"
              fill="white"
            />
            <path
              d="M1329.86 147.06C1327.96 147.06 1326.41 145.508 1326.41 143.611C1326.41 141.714 1327.96 140.162 1329.86 140.162C1331.76 140.162 1333.31 141.714 1333.31 143.611C1333.31 145.508 1331.76 147.06 1329.86 147.06Z"
              fill="white"
            />
            <path
              d="M1382.26 164.649C1380.13 164.649 1378.4 162.924 1378.4 160.797C1378.4 158.671 1380.13 157.004 1382.26 157.004C1384.38 157.004 1386.05 158.728 1386.05 160.797C1386.05 162.867 1384.33 164.649 1382.26 164.649Z"
              fill="white"
            />
            <path
              d="M1434.71 182.237C1432.35 182.237 1430.45 180.341 1430.45 177.984C1430.45 175.627 1432.35 173.73 1434.71 173.73C1437.07 173.73 1438.96 175.627 1438.96 177.984C1438.96 180.341 1437.07 182.237 1434.71 182.237Z"
              fill="white"
            />
            <path
              d="M1487.16 199.941C1484.57 199.941 1482.44 197.814 1482.44 195.228C1482.44 192.641 1484.57 190.515 1487.16 190.515C1489.75 190.515 1491.88 192.641 1491.88 195.228C1491.88 197.814 1489.75 199.941 1487.16 199.941Z"
              fill="white"
            />
            <path
              d="M1539.61 217.702C1536.68 217.702 1534.26 215.288 1534.26 212.357C1534.26 209.425 1536.68 207.011 1539.61 207.011C1542.55 207.011 1544.96 209.425 1544.96 212.357C1544.96 215.288 1542.6 217.702 1539.61 217.702Z"
              fill="white"
            />
            <path
              d="M1592.06 235.636C1588.73 235.636 1586.03 232.935 1586.03 229.601C1586.03 226.267 1588.73 223.565 1592.06 223.565C1595.4 223.565 1598.1 226.267 1598.1 229.601C1598.1 232.935 1595.4 235.636 1592.06 235.636Z"
              fill="white"
            />
            <path
              d="M700.548 0.658702C696.004 0.658702 692.324 -3.02002 692.324 -7.6184C692.324 -12.2168 696.004 -15.8955 700.548 -15.8955C705.092 -15.8955 708.83 -12.2168 708.83 -7.6184C708.83 -3.02002 705.149 0.658702 700.548 0.658702Z"
              fill="white"
            />
            <path
              d="M753 17.6727C748.514 17.6727 744.891 14.0514 744.891 9.56801C744.891 5.08458 748.514 1.46338 753 1.46338C757.486 1.46338 761.11 5.08458 761.11 9.56801C761.11 14.0514 757.486 17.6727 753 17.6727Z"
              fill="white"
            />
            <path
              d="M805.453 34.7442C801.024 34.7442 797.458 31.1805 797.458 26.7545C797.458 22.3286 801.024 18.7648 805.453 18.7648C809.881 18.7648 813.447 22.3286 813.447 26.7545C813.447 31.1805 809.881 34.7442 805.453 34.7442Z"
              fill="white"
            />
            <path
              d="M857.847 51.5284C853.706 51.5284 850.313 48.137 850.313 43.9985C850.313 39.8599 853.706 36.4686 857.847 36.4686C861.988 36.4686 865.382 39.8599 865.382 43.9985C865.382 48.137 861.988 51.5284 857.847 51.5284Z"
              fill="white"
            />
            <path
              d="M910.3 67.5077C906.791 67.5077 903.916 64.6912 903.916 61.1275C903.916 57.5637 906.791 54.7472 910.3 54.7472C913.808 54.7472 916.684 57.5637 916.684 61.1275C916.684 64.6912 913.866 67.5077 910.3 67.5077Z"
              fill="white"
            />
            <path
              d="M962.752 83.0847C960.164 83.0847 958.036 80.958 958.036 78.3714C958.036 75.7848 960.164 73.658 962.752 73.658C965.34 73.658 967.468 75.7848 967.468 78.3714C967.468 80.958 965.34 83.0847 962.752 83.0847Z"
              fill="white"
            />
            <path
              d="M1015.2 98.7193C1013.48 98.7193 1012.04 97.2822 1012.04 95.5578C1012.04 93.8335 1013.48 92.3965 1015.2 92.3965C1016.93 92.3965 1018.37 93.8335 1018.37 95.5578C1018.37 97.2822 1016.93 98.7193 1015.2 98.7193Z"
              fill="white"
            />
            <path
              d="M1067.66 114.871C1066.45 114.871 1065.53 113.894 1065.53 112.744C1065.53 111.595 1066.45 110.618 1067.66 110.618C1068.86 110.618 1069.78 111.595 1069.78 112.744C1069.78 113.894 1068.81 114.871 1067.66 114.871Z"
              fill="white"
            />
            <path
              d="M1120.05 131.828C1119.02 131.828 1118.15 130.965 1118.15 129.931C1118.15 128.896 1119.02 128.034 1120.05 128.034C1121.09 128.034 1121.95 128.896 1121.95 129.931C1121.95 130.965 1121.09 131.828 1120.05 131.828Z"
              fill="white"
            />
            <path
              d="M1172.5 149.187C1171.35 149.187 1170.49 148.267 1170.49 147.175C1170.49 146.083 1171.41 145.163 1172.5 145.163C1173.6 145.163 1174.57 146.083 1174.57 147.175C1174.57 148.267 1173.65 149.187 1172.5 149.187Z"
              fill="white"
            />
            <path
              d="M1224.96 166.545C1223.75 166.545 1222.77 165.568 1222.77 164.361C1222.77 163.154 1223.75 162.177 1224.96 162.177C1226.16 162.177 1227.14 163.154 1227.14 164.361C1227.14 165.568 1226.16 166.545 1224.96 166.545Z"
              fill="white"
            />
            <path
              d="M1277.41 184.019C1276.09 184.019 1274.99 182.927 1274.99 181.605C1274.99 180.283 1276.09 179.191 1277.41 179.191C1278.73 179.191 1279.82 180.283 1279.82 181.605C1279.82 182.927 1278.73 184.019 1277.41 184.019Z"
              fill="white"
            />
            <path
              d="M1329.86 201.551C1328.31 201.551 1327.04 200.286 1327.04 198.734C1327.04 197.182 1328.31 195.918 1329.86 195.918C1331.41 195.918 1332.68 197.182 1332.68 198.734C1332.68 200.286 1331.41 201.551 1329.86 201.551Z"
              fill="white"
            />
            <path
              d="M1382.26 219.139C1380.53 219.139 1379.09 217.702 1379.09 215.978C1379.09 214.254 1380.53 212.817 1382.26 212.817C1383.98 212.817 1385.42 214.254 1385.42 215.978C1385.42 217.702 1383.98 219.139 1382.26 219.139Z"
              fill="white"
            />
            <path
              d="M1434.71 236.843C1432.64 236.843 1430.97 235.176 1430.97 233.107C1430.97 231.038 1432.64 229.371 1434.71 229.371C1436.78 229.371 1438.45 231.038 1438.45 233.107C1438.45 235.176 1436.78 236.843 1434.71 236.843Z"
              fill="white"
            />
            <path
              d="M1487.16 254.604C1484.8 254.604 1482.9 252.708 1482.9 250.351C1482.9 247.994 1484.8 246.097 1487.16 246.097C1489.52 246.097 1491.47 247.994 1491.47 250.351C1491.47 252.708 1489.58 254.604 1487.16 254.604Z"
              fill="white"
            />
            <path
              d="M1539.61 272.538C1536.85 272.538 1534.61 270.296 1534.61 267.537C1534.61 264.778 1536.85 262.537 1539.61 262.537C1542.37 262.537 1544.62 264.778 1544.62 267.537C1544.62 270.296 1542.37 272.538 1539.61 272.538Z"
              fill="white"
            />
            <path
              d="M1592.06 290.529C1588.84 290.529 1586.26 287.943 1586.26 284.724C1586.26 281.505 1588.84 278.918 1592.06 278.918C1595.29 278.918 1597.87 281.505 1597.87 284.724C1597.87 287.943 1595.29 290.529 1592.06 290.529Z"
              fill="white"
            />
            <path
              d="M543.191 2.49801C539.568 2.49801 536.692 -0.433501 536.692 -4.05473C536.692 -7.67596 539.625 -10.6074 543.191 -10.6074C546.757 -10.6074 549.69 -7.67596 549.69 -4.05473C549.69 -0.433501 546.757 2.49801 543.191 2.49801Z"
              fill="white"
            />
            <path
              d="M595.643 19.9719C591.905 19.9719 588.857 16.9255 588.857 13.1318C588.857 9.33813 591.905 6.34918 595.643 6.34918C599.382 6.34918 602.488 9.39561 602.488 13.1318C602.488 16.868 599.439 19.9719 595.643 19.9719Z"
              fill="white"
            />
            <path
              d="M648.096 37.4457C652.003 37.4457 655.17 34.2804 655.17 30.3757C655.17 26.471 652.003 23.3057 648.096 23.3057C644.189 23.3057 641.022 26.471 641.022 30.3757C641.022 34.2804 644.189 37.4457 648.096 37.4457Z"
              fill="white"
            />
            <path
              d="M700.548 54.9196C696.465 54.9196 693.186 51.6432 693.186 47.5622C693.186 43.4811 696.465 40.2048 700.548 40.2048C704.631 40.2048 707.91 43.4811 707.91 47.5622C707.91 51.6432 704.631 54.9196 700.548 54.9196Z"
              fill="white"
            />
            <path
              d="M753 72.3935C748.744 72.3935 745.351 68.9447 745.351 64.7487C745.351 60.5526 748.802 57.1039 753 57.1039C757.199 57.1039 760.65 60.5526 760.65 64.7487C760.65 68.9447 757.199 72.3935 753 72.3935Z"
              fill="white"
            />
            <path
              d="M805.453 89.8673C809.836 89.8673 813.389 86.3159 813.389 81.9351C813.389 77.5543 809.836 74.0029 805.453 74.0029C801.069 74.0029 797.516 77.5543 797.516 81.9351C797.516 86.3159 801.069 89.8673 805.453 89.8673Z"
              fill="white"
            />
            <path
              d="M857.847 107.341C853.304 107.341 849.681 103.662 849.681 99.1791C849.681 94.6956 853.361 91.0169 857.847 91.0169C862.333 91.0169 866.014 94.6956 866.014 99.1791C866.014 103.662 862.333 107.341 857.847 107.341Z"
              fill="white"
            />
            <path
              d="M910.3 124.24C905.929 124.24 902.42 120.734 902.42 116.365C902.42 111.997 905.929 108.491 910.3 108.491C914.671 108.491 918.179 111.997 918.179 116.365C918.179 120.734 914.671 124.24 910.3 124.24Z"
              fill="white"
            />
            <path
              d="M962.752 140.507C958.899 140.507 955.793 137.403 955.793 133.552C955.793 129.701 958.899 126.597 962.752 126.597C966.605 126.597 969.711 129.701 969.711 133.552C969.711 137.403 966.605 140.507 962.752 140.507Z"
              fill="white"
            />
            <path
              d="M1015.2 156.371C1012.1 156.371 1009.57 153.842 1009.57 150.738C1009.57 147.634 1012.1 145.105 1015.2 145.105C1018.31 145.105 1020.84 147.634 1020.84 150.738C1020.84 153.842 1018.31 156.371 1015.2 156.371Z"
              fill="white"
            />
            <path
              d="M1067.66 172.351C1070.1 172.351 1072.09 170.369 1072.09 167.925C1072.09 165.48 1070.1 163.499 1067.66 163.499C1065.21 163.499 1063.23 165.48 1063.23 167.925C1063.23 170.369 1065.21 172.351 1067.66 172.351Z"
              fill="white"
            />
            <path
              d="M1120.05 188.847C1117.98 188.847 1116.31 187.181 1116.31 185.111C1116.31 183.042 1117.98 181.375 1120.05 181.375C1122.12 181.375 1123.79 183.042 1123.79 185.111C1123.79 187.181 1122.12 188.847 1120.05 188.847Z"
              fill="white"
            />
            <path
              d="M1172.5 205.804C1170.61 205.804 1169 204.252 1169 202.298C1169 200.344 1170.55 198.849 1172.5 198.849C1174.46 198.849 1175.95 200.401 1175.95 202.298C1175.95 204.195 1174.4 205.804 1172.5 205.804Z"
              fill="white"
            />
            <path
              d="M1224.96 222.761C1223.17 222.761 1221.74 221.324 1221.74 219.484C1221.74 217.645 1223.17 216.208 1224.96 216.208C1226.74 216.208 1228.23 217.645 1228.23 219.484C1228.23 221.324 1226.8 222.761 1224.96 222.761Z"
              fill="white"
            />
            <path
              d="M1277.41 239.717C1275.74 239.717 1274.36 238.338 1274.36 236.671C1274.36 235.004 1275.74 233.624 1277.41 233.624C1279.08 233.624 1280.4 234.946 1280.4 236.671C1280.4 238.395 1279.08 239.717 1277.41 239.717Z"
              fill="white"
            />
            <path
              d="M1329.86 256.731C1328.31 256.731 1327.1 255.467 1327.1 253.915C1327.1 252.363 1328.37 251.098 1329.86 251.098C1331.36 251.098 1332.68 252.363 1332.68 253.915C1332.68 255.467 1331.41 256.731 1329.86 256.731Z"
              fill="white"
            />
            <path
              d="M1382.26 273.688C1380.82 273.688 1379.67 272.538 1379.67 271.101C1379.67 269.664 1380.82 268.515 1382.26 268.515C1383.69 268.515 1384.79 269.664 1384.79 271.101C1384.79 272.538 1383.64 273.688 1382.26 273.688Z"
              fill="white"
            />
            <path
              d="M1434.71 290.817C1433.33 290.817 1432.23 289.725 1432.23 288.345C1432.23 286.966 1433.33 285.873 1434.71 285.873C1436.09 285.873 1437.18 286.966 1437.18 288.345C1437.18 289.725 1436.09 290.817 1434.71 290.817Z"
              fill="white"
            />
            <path
              d="M1487.16 307.946C1485.84 307.946 1484.74 306.854 1484.74 305.532C1484.74 304.21 1485.84 303.117 1487.16 303.117C1488.48 303.117 1489.58 304.21 1489.58 305.532C1489.58 306.854 1488.48 307.946 1487.16 307.946Z"
              fill="white"
            />
            <path
              d="M1539.61 325.075C1538.29 325.075 1537.25 323.983 1537.25 322.718C1537.25 321.453 1538.35 320.361 1539.61 320.361C1540.88 320.361 1541.97 321.453 1541.97 322.718C1541.97 323.983 1540.94 325.075 1539.61 325.075Z"
              fill="white"
            />
            <path
              d="M1592.06 342.261C1590.74 342.261 1589.71 341.226 1589.71 339.904C1589.71 338.582 1590.74 337.548 1592.06 337.548C1593.39 337.548 1594.42 338.582 1594.42 339.904C1594.42 341.226 1593.39 342.261 1592.06 342.261Z"
              fill="white"
            />
            <path
              d="M385.892 2.49805C384.224 2.49805 382.901 1.17599 382.901 -0.490928C382.901 -2.15784 384.224 -3.47986 385.892 -3.47986C387.56 -3.47986 388.883 -2.15784 388.883 -0.490928C388.883 1.17599 387.56 2.49805 385.892 2.49805Z"
              fill="white"
            />
            <path
              d="M438.344 19.9144C436.619 19.9144 435.181 18.4774 435.181 16.753C435.181 15.0286 436.619 13.5917 438.344 13.5917C440.07 13.5917 441.507 15.0286 441.507 16.753C441.507 18.4774 440.07 19.9144 438.344 19.9144Z"
              fill="white"
            />
            <path
              d="M490.796 37.5032C488.841 37.5032 487.231 35.8938 487.231 33.9395C487.231 31.9851 488.841 30.3757 490.796 30.3757C492.752 30.3757 494.362 31.9851 494.362 33.9395C494.362 35.8938 492.752 37.5032 490.796 37.5032Z"
              fill="white"
            />
            <path
              d="M543.191 55.3219C540.891 55.3219 538.993 53.4251 538.993 51.1259C538.993 48.8267 540.891 46.9299 543.191 46.9299C545.492 46.9299 547.39 48.8267 547.39 51.1259C547.39 53.4251 545.492 55.3219 543.191 55.3219Z"
              fill="white"
            />
            <path
              d="M595.644 73.3706C592.825 73.3706 590.582 71.0714 590.582 68.3124C590.582 65.5533 592.825 63.2542 595.644 63.2542C598.462 63.2542 600.705 65.5533 600.705 68.3124C600.705 71.0714 598.462 73.3706 595.644 73.3706Z"
              fill="white"
            />
            <path
              d="M648.096 91.4193C644.818 91.4193 642.172 88.7752 642.172 85.4988C642.172 82.2225 644.818 79.5784 648.096 79.5784C651.374 79.5784 654.02 82.2225 654.02 85.4988C654.02 88.7752 651.374 91.4193 648.096 91.4193Z"
              fill="white"
            />
            <path
              d="M700.548 109.468C696.81 109.468 693.762 106.422 693.762 102.685C693.762 98.9491 696.81 95.9027 700.548 95.9027C704.286 95.9027 707.335 98.9491 707.335 102.685C707.335 106.422 704.286 109.468 700.548 109.468Z"
              fill="white"
            />
            <path
              d="M753 127.344C748.859 127.344 745.524 124.01 745.524 119.872C745.524 115.733 748.859 112.399 753 112.399C757.141 112.399 760.477 115.733 760.477 119.872C760.477 124.01 757.141 127.344 753 127.344Z"
              fill="white"
            />
            <path
              d="M805.453 144.99C801.082 144.99 797.573 141.484 797.573 137.116C797.573 132.747 801.082 129.241 805.453 129.241C809.824 129.241 813.332 132.747 813.332 137.116C813.332 141.484 809.824 144.99 805.453 144.99Z"
              fill="white"
            />
            <path
              d="M857.847 162.062C853.534 162.062 850.083 158.613 850.083 154.302C850.083 149.991 853.534 146.542 857.847 146.542C862.161 146.542 865.612 149.991 865.612 154.302C865.612 158.613 862.161 162.062 857.847 162.062Z"
              fill="white"
            />
            <path
              d="M910.3 178.214C906.561 178.214 903.571 175.225 903.571 171.489C903.571 167.752 906.561 164.763 910.3 164.763C914.038 164.763 917.029 167.752 917.029 171.489C917.029 175.225 914.038 178.214 910.3 178.214Z"
              fill="white"
            />
            <path
              d="M962.752 194.423C959.589 194.423 957 191.837 957 188.733C957 185.629 959.589 183.042 962.752 183.042C965.915 183.042 968.446 185.629 968.446 188.733C968.446 191.837 965.858 194.423 962.752 194.423Z"
              fill="white"
            />
            <path
              d="M1015.2 210.747C1012.56 210.747 1010.37 208.563 1010.37 205.919C1010.37 203.275 1012.56 201.091 1015.2 201.091C1017.85 201.091 1020.04 203.275 1020.04 205.919C1020.04 208.563 1017.85 210.747 1015.2 210.747Z"
              fill="white"
            />
            <path
              d="M1067.66 227.072C1065.47 227.072 1063.69 225.29 1063.69 223.106C1063.69 220.921 1065.47 219.139 1067.66 219.139C1069.84 219.139 1071.62 220.921 1071.62 223.106C1071.62 225.29 1069.84 227.072 1067.66 227.072Z"
              fill="white"
            />
            <path
              d="M1120.05 243.568C1118.27 243.568 1116.77 242.131 1116.77 240.292C1116.77 238.453 1118.21 237.016 1120.05 237.016C1121.89 237.016 1123.33 238.453 1123.33 240.292C1123.33 242.131 1121.89 243.568 1120.05 243.568Z"
              fill="white"
            />
            <path
              d="M1172.5 260.295C1170.95 260.295 1169.69 259.03 1169.69 257.478C1169.69 255.926 1170.95 254.662 1172.5 254.662C1174.06 254.662 1175.32 255.926 1175.32 257.478C1175.32 259.03 1174.06 260.295 1172.5 260.295Z"
              fill="white"
            />
            <path
              d="M1224.96 277.309C1223.52 277.309 1222.37 276.159 1222.37 274.722C1222.37 273.285 1223.52 272.136 1224.96 272.136C1226.39 272.136 1227.54 273.285 1227.54 274.722C1227.54 276.159 1226.39 277.309 1224.96 277.309Z"
              fill="white"
            />
            <path
              d="M1277.41 294.323C1276.09 294.323 1274.99 293.231 1274.99 291.909C1274.99 290.587 1276.09 289.495 1277.41 289.495C1278.73 289.495 1279.82 290.587 1279.82 291.909C1279.82 293.231 1278.73 294.323 1277.41 294.323Z"
              fill="white"
            />
            <path
              d="M1329.86 311.452C1328.54 311.452 1327.5 310.417 1327.5 309.095C1327.5 307.773 1328.54 306.739 1329.86 306.739C1331.18 306.739 1332.22 307.773 1332.22 309.095C1332.22 310.417 1331.18 311.452 1329.86 311.452Z"
              fill="white"
            />
            <path
              d="M1382.26 328.696C1380.93 328.696 1379.84 327.604 1379.84 326.282C1379.84 324.96 1380.93 323.868 1382.26 323.868C1383.58 323.868 1384.67 324.96 1384.67 326.282C1384.67 327.604 1383.58 328.696 1382.26 328.696Z"
              fill="white"
            />
            <path
              d="M1434.71 345.997C1433.27 345.997 1432.18 344.848 1432.18 343.468C1432.18 342.089 1433.33 340.939 1434.71 340.939C1436.09 340.939 1437.24 342.089 1437.24 343.468C1437.24 344.848 1436.09 345.997 1434.71 345.997Z"
              fill="white"
            />
            <path
              d="M1487.16 363.356C1485.66 363.356 1484.46 362.149 1484.46 360.655C1484.46 359.16 1485.66 357.953 1487.16 357.953C1488.66 357.953 1489.86 359.16 1489.86 360.655C1489.86 362.149 1488.66 363.356 1487.16 363.356Z"
              fill="white"
            />
            <path
              d="M1539.61 380.715C1538.06 380.715 1536.79 379.451 1536.79 377.899C1536.79 376.347 1538.06 375.082 1539.61 375.082C1541.16 375.082 1542.49 376.347 1542.49 377.899C1542.49 379.451 1541.22 380.715 1539.61 380.715Z"
              fill="white"
            />
            <path
              d="M1592.06 398.246C1590.28 398.246 1588.9 396.809 1588.9 395.085C1588.9 393.361 1590.34 391.924 1592.06 391.924C1593.79 391.924 1595.23 393.361 1595.23 395.085C1595.23 396.809 1593.79 398.246 1592.06 398.246Z"
              fill="white"
            />
            <path
              d="M228.593 10.0279C224.797 10.0279 221.691 6.92401 221.691 3.13035C221.691 -0.663321 224.797 -3.76721 228.593 -3.76721C232.388 -3.76721 235.494 -0.663321 235.494 3.13035C235.494 6.92401 232.388 10.0279 228.593 10.0279Z"
              fill="white"
            />
            <path
              d="M280.987 27.9041C276.789 27.9041 273.338 24.5128 273.338 20.2593C273.338 16.0058 276.731 12.6145 280.987 12.6145C285.243 12.6145 288.637 16.0058 288.637 20.2593C288.637 24.5128 285.243 27.9041 280.987 27.9041Z"
              fill="white"
            />
            <path
              d="M333.44 44.8032C329.414 44.8032 326.135 41.5269 326.135 37.5033C326.135 33.4797 329.414 30.2034 333.44 30.2034C337.466 30.2034 340.744 33.4797 340.744 37.5033C340.744 41.5269 337.466 44.8032 333.44 44.8032Z"
              fill="white"
            />
            <path
              d="M385.892 58.8283C383.591 58.8283 381.751 56.9889 381.751 54.6897C381.751 52.3905 383.591 50.5511 385.892 50.5511C388.192 50.5511 390.033 52.3905 390.033 54.6897C390.033 56.9889 388.192 58.8283 385.892 58.8283Z"
              fill="white"
            />
            <path
              d="M438.344 73.5431C437.424 73.5431 436.676 72.7959 436.676 71.8762C436.676 70.9565 437.424 70.2093 438.344 70.2093C439.264 70.2093 440.012 70.9565 440.012 71.8762C440.012 72.7959 439.264 73.5431 438.344 73.5431Z"
              fill="white"
            />
            <path
              d="M490.796 91.1895C489.646 91.1895 488.726 90.2698 488.726 89.1202C488.726 87.9706 489.646 87.0509 490.796 87.0509C491.947 87.0509 492.867 87.9706 492.867 89.1202C492.867 90.2698 491.947 91.1895 490.796 91.1895Z"
              fill="white"
            />
            <path
              d="M543.191 109.353C541.466 109.353 540.085 107.974 540.085 106.249C540.085 104.525 541.466 103.145 543.191 103.145C544.917 103.145 546.297 104.525 546.297 106.249C546.297 107.974 544.917 109.353 543.191 109.353Z"
              fill="white"
            />
            <path
              d="M595.644 127.976C593.17 127.976 591.158 125.965 591.158 123.436C591.158 120.906 593.17 118.952 595.644 118.952C598.117 118.952 600.187 120.964 600.187 123.436C600.187 125.907 598.174 127.976 595.644 127.976Z"
              fill="white"
            />
            <path
              d="M648.096 146.772C644.702 146.772 641.999 144.071 641.999 140.68C641.999 137.288 644.76 134.587 648.096 134.587C651.432 134.587 654.192 137.288 654.192 140.68C654.192 144.071 651.489 146.772 648.096 146.772Z"
              fill="white"
            />
            <path
              d="M700.548 165.396C696.407 165.396 693.014 162.062 693.014 157.866C693.014 153.67 696.407 150.336 700.548 150.336C704.689 150.336 708.082 153.67 708.082 157.866C708.082 162.062 704.747 165.396 700.548 165.396Z"
              fill="white"
            />
            <path
              d="M753 183.559C748.342 183.559 744.546 179.766 744.546 175.11C744.546 170.454 748.342 166.66 753 166.66C757.659 166.66 761.455 170.454 761.455 175.11C761.455 179.766 757.659 183.559 753 183.559Z"
              fill="white"
            />
            <path
              d="M805.453 201.148C800.564 201.148 796.538 197.182 796.538 192.239C796.538 187.296 800.506 183.33 805.453 183.33C810.399 183.33 814.367 187.296 814.367 192.239C814.367 197.182 810.399 201.148 805.453 201.148Z"
              fill="white"
            />
            <path
              d="M857.847 218.162C853.074 218.162 849.163 214.254 849.163 209.483C849.163 204.712 853.074 200.803 857.847 200.803C862.621 200.803 866.532 204.712 866.532 209.483C866.532 214.254 862.621 218.162 857.847 218.162Z"
              fill="white"
            />
            <path
              d="M910.3 234.314C914.524 234.314 917.949 230.891 917.949 226.669C917.949 222.447 914.524 219.024 910.3 219.024C906.075 219.024 902.65 222.447 902.65 226.669C902.65 230.891 906.075 234.314 910.3 234.314Z"
              fill="white"
            />
            <path
              d="M962.752 249.949C959.416 249.949 956.656 247.247 956.656 243.856C956.656 240.464 959.359 237.763 962.752 237.763C966.145 237.763 968.791 240.464 968.791 243.856C968.791 247.247 966.088 249.949 962.752 249.949Z"
              fill="white"
            />
            <path
              d="M1015.2 265.411C1012.79 265.411 1010.89 263.456 1010.89 261.1C1010.89 258.743 1012.85 256.789 1015.2 256.789C1017.56 256.789 1019.52 258.743 1019.52 261.1C1019.52 263.456 1017.56 265.411 1015.2 265.411Z"
              fill="white"
            />
            <path
              d="M1067.66 281.103C1066.1 281.103 1064.84 279.838 1064.84 278.286C1064.84 276.734 1066.1 275.47 1067.66 275.47C1069.21 275.47 1070.47 276.734 1070.47 278.286C1070.47 279.838 1069.21 281.103 1067.66 281.103Z"
              fill="white"
            />
            <path
              d="M1120.05 297.427C1118.96 297.427 1118.1 296.565 1118.1 295.473C1118.1 294.38 1118.96 293.518 1120.05 293.518C1121.14 293.518 1122.01 294.38 1122.01 295.473C1122.01 296.565 1121.14 297.427 1120.05 297.427Z"
              fill="white"
            />
            <path
              d="M1172.5 314.269C1173.39 314.269 1174.11 313.548 1174.11 312.659C1174.11 311.77 1173.39 311.05 1172.5 311.05C1171.61 311.05 1170.89 311.77 1170.89 312.659C1170.89 313.548 1171.61 314.269 1172.5 314.269Z"
              fill="white"
            />
            <path
              d="M1224.96 331.168C1224.21 331.168 1223.63 330.593 1223.63 329.845C1223.63 329.098 1224.21 328.523 1224.96 328.523C1225.7 328.523 1226.28 329.098 1226.28 329.845C1226.28 330.593 1225.7 331.168 1224.96 331.168Z"
              fill="white"
            />
            <path
              d="M1277.41 348.411C1276.66 348.411 1276.03 347.779 1276.03 347.032C1276.03 346.285 1276.66 345.652 1277.41 345.652C1278.16 345.652 1278.79 346.285 1278.79 347.032C1278.79 347.779 1278.16 348.411 1277.41 348.411Z"
              fill="white"
            />
            <path
              d="M1329.86 365.828C1329 365.828 1328.25 365.081 1328.25 364.218C1328.25 363.356 1328.94 362.609 1329.86 362.609C1330.78 362.609 1331.47 363.356 1331.47 364.218C1331.47 365.081 1330.72 365.828 1329.86 365.828Z"
              fill="white"
            />
            <path
              d="M1382.26 383.474C1381.16 383.474 1380.24 382.555 1380.24 381.462C1380.24 380.37 1381.16 379.451 1382.26 379.451C1383.35 379.451 1384.27 380.37 1384.27 381.462C1384.27 382.555 1383.35 383.474 1382.26 383.474Z"
              fill="white"
            />
            <path
              d="M1434.71 401.063C1433.38 401.063 1432.29 399.971 1432.29 398.649C1432.29 397.327 1433.38 396.235 1434.71 396.235C1436.03 396.235 1437.12 397.327 1437.12 398.649C1437.12 399.971 1436.03 401.063 1434.71 401.063Z"
              fill="white"
            />
            <path
              d="M1487.16 418.652C1485.61 418.652 1484.34 417.387 1484.34 415.835C1484.34 414.283 1485.61 413.019 1487.16 413.019C1488.71 413.019 1489.98 414.283 1489.98 415.835C1489.98 417.387 1488.71 418.652 1487.16 418.652Z"
              fill="white"
            />
            <path
              d="M1539.61 436.413C1537.77 436.413 1536.28 434.919 1536.28 433.022C1536.28 431.125 1537.77 429.63 1539.61 429.63C1541.45 429.63 1543.01 431.125 1543.01 433.022C1543.01 434.919 1541.51 436.413 1539.61 436.413Z"
              fill="white"
            />
            <path
              d="M1592.06 454.117C1589.88 454.117 1588.15 452.335 1588.15 450.208C1588.15 448.081 1589.88 446.3 1592.06 446.3C1594.25 446.3 1595.98 448.024 1595.98 450.208C1595.98 452.392 1594.19 454.117 1592.06 454.117Z"
              fill="white"
            />
            <path
              d="M18.4959 1.00359C12.0543 1.00359 6.82062 -4.22706 6.82062 -10.6648C6.82062 -17.1025 12.0543 -22.3332 18.4959 -22.3332C24.9374 -22.3332 30.1711 -17.1025 30.1711 -10.6648C30.1711 -4.22706 24.9374 1.00359 18.4959 1.00359V1.00359Z"
              fill="white"
            />
            <path
              d="M70.7756 17.3278C64.7942 17.3278 59.9631 12.4995 59.9631 6.52164C59.9631 0.543742 64.7942 -4.28455 70.7756 -4.28455C76.757 -4.28455 81.5882 0.543742 81.5882 6.52164C81.5882 12.4995 76.757 17.3278 70.7756 17.3278Z"
              fill="white"
            />
            <path
              d="M123.113 33.6521C117.592 33.6521 113.106 29.1687 113.106 23.6506C113.106 18.1326 117.592 13.7066 123.113 13.7066C128.634 13.7066 133.063 18.19 133.063 23.6506C133.063 29.1112 128.577 33.6521 123.113 33.6521Z"
              fill="white"
            />
            <path
              d="M175.45 49.9763C170.389 49.9763 166.305 45.8953 166.305 40.8371C166.305 35.7788 170.389 31.6978 175.45 31.6978C180.511 31.6978 184.595 35.7788 184.595 40.8371C184.595 45.8953 180.511 49.9763 175.45 49.9763Z"
              fill="white"
            />
            <path
              d="M227.787 66.3006C223.186 66.3006 219.505 62.5644 219.505 58.0235C219.505 53.4826 223.244 49.7464 227.787 49.7464C232.331 49.7464 236.069 53.4826 236.069 58.0235C236.069 62.5644 232.331 66.3006 227.787 66.3006Z"
              fill="white"
            />
            <path
              d="M280.125 82.6249C284.254 82.6249 287.601 79.2794 287.601 75.1525C287.601 71.0256 284.254 67.6801 280.125 67.6801C275.995 67.6801 272.648 71.0256 272.648 75.1525C272.648 79.2794 275.995 82.6249 280.125 82.6249Z"
              fill="white"
            />
            <path
              d="M332.462 98.9491C328.839 98.9491 325.848 96.0177 325.848 92.3389C325.848 88.6602 328.781 85.7288 332.462 85.7288C336.143 85.7288 339.076 88.6602 339.076 92.3389C339.076 96.0177 336.143 98.9491 332.462 98.9491Z"
              fill="white"
            />
            <path
              d="M384.799 114.699C381.923 114.699 379.565 112.342 379.565 109.468C379.565 106.594 381.923 104.237 384.799 104.237C387.675 104.237 390.033 106.594 390.033 109.468C390.033 112.342 387.675 114.699 384.799 114.699Z"
              fill="white"
            />
            <path
              d="M437.136 130.563C434.951 130.563 433.225 128.781 433.225 126.597C433.225 124.413 435.008 122.688 437.136 122.688C439.264 122.688 441.105 124.47 441.105 126.597C441.105 128.724 439.322 130.563 437.136 130.563Z"
              fill="white"
            />
            <path
              d="M489.416 147.347C487.461 147.347 485.85 145.738 485.85 143.783C485.85 141.829 487.461 140.22 489.416 140.22C491.372 140.22 492.982 141.829 492.982 143.783C492.982 145.738 491.372 147.347 489.416 147.347Z"
              fill="white"
            />
            <path
              d="M541.753 164.649C539.683 164.649 538.073 162.982 538.073 160.912C538.073 158.843 539.74 157.234 541.753 157.234C543.766 157.234 545.434 158.901 545.434 160.912C545.434 162.924 543.766 164.649 541.753 164.649Z"
              fill="white"
            />
            <path
              d="M594.091 182.122C591.905 182.122 590.122 180.341 590.122 178.156C590.122 175.972 591.905 174.19 594.091 174.19C596.276 174.19 598.059 175.972 598.059 178.156C598.059 180.341 596.276 182.122 594.091 182.122Z"
              fill="white"
            />
            <path
              d="M646.428 199.999C643.84 199.999 641.712 197.872 641.712 195.285C641.712 192.699 643.84 190.572 646.428 190.572C649.016 190.572 651.144 192.699 651.144 195.285C651.144 197.872 649.016 199.999 646.428 199.999Z"
              fill="white"
            />
            <path
              d="M698.765 218.622C695.372 218.622 692.611 215.863 692.611 212.472C692.611 209.08 695.372 206.321 698.765 206.321C702.158 206.321 704.919 209.08 704.919 212.472C704.919 215.863 702.158 218.622 698.765 218.622Z"
              fill="white"
            />
            <path
              d="M751.102 237.82C746.559 237.82 742.878 234.142 742.878 229.601C742.878 225.06 746.559 221.381 751.102 221.381C755.646 221.381 759.327 225.06 759.327 229.601C759.327 234.142 755.646 237.82 751.102 237.82Z"
              fill="white"
            />
            <path
              d="M803.44 255.869C798.436 255.869 794.353 251.788 794.353 246.787C794.353 241.786 798.436 237.705 803.44 237.705C808.443 237.705 812.527 241.786 812.527 246.787C812.527 251.788 808.443 255.869 803.44 255.869Z"
              fill="white"
            />
            <path
              d="M855.777 272.423C851.118 272.423 847.323 268.63 847.323 263.916C847.323 259.203 851.118 255.467 855.777 255.467C860.436 255.467 864.231 259.26 864.231 263.916C864.231 268.572 860.436 272.423 855.777 272.423Z"
              fill="white"
            />
            <path
              d="M908.114 287.713C911.767 287.713 914.728 284.753 914.728 281.103C914.728 277.452 911.767 274.492 908.114 274.492C904.461 274.492 901.5 277.452 901.5 281.103C901.5 284.753 904.461 287.713 908.114 287.713Z"
              fill="white"
            />
            <path
              d="M960.394 302.715C957.921 302.715 955.965 300.703 955.965 298.289C955.965 295.875 957.978 293.863 960.394 293.863C962.809 293.863 964.822 295.875 964.822 298.289C964.822 300.703 962.809 302.715 960.394 302.715Z"
              fill="white"
            />
            <path
              d="M1012.73 318.522C1011.01 318.522 1009.63 317.142 1009.63 315.418C1009.63 313.694 1011.01 312.314 1012.73 312.314C1014.46 312.314 1015.84 313.694 1015.84 315.418C1015.84 317.142 1014.46 318.522 1012.73 318.522Z"
              fill="white"
            />
            <path
              d="M1065.07 335.134C1063.69 335.134 1062.54 333.984 1062.54 332.605C1062.54 331.225 1063.69 330.075 1065.07 330.075C1066.45 330.075 1067.6 331.225 1067.6 332.605C1067.6 333.984 1066.45 335.134 1065.07 335.134Z"
              fill="white"
            />
            <path
              d="M1117.41 351.918C1116.2 351.918 1115.22 350.941 1115.22 349.734C1115.22 348.527 1116.2 347.549 1117.41 347.549C1118.61 347.549 1119.59 348.527 1119.59 349.734C1119.59 350.941 1118.61 351.918 1117.41 351.918Z"
              fill="white"
            />
            <path
              d="M1169.74 369.047C1168.54 369.047 1167.61 368.07 1167.61 366.92C1167.61 365.77 1168.59 364.793 1169.74 364.793C1170.89 364.793 1171.87 365.77 1171.87 366.92C1171.87 368.07 1170.89 369.047 1169.74 369.047Z"
              fill="white"
            />
            <path
              d="M1222.08 386.463C1220.76 386.463 1219.72 385.371 1219.72 384.106C1219.72 382.842 1220.76 381.75 1222.08 381.75C1223.4 381.75 1224.5 382.842 1224.5 384.106C1224.5 385.371 1223.4 386.463 1222.08 386.463Z"
              fill="white"
            />
            <path
              d="M1274.42 403.994C1272.86 403.994 1271.66 402.73 1271.66 401.178C1271.66 399.626 1272.92 398.361 1274.42 398.361C1275.91 398.361 1277.24 399.626 1277.24 401.178C1277.24 402.73 1275.97 403.994 1274.42 403.994Z"
              fill="white"
            />
            <path
              d="M1326.75 421.756C1324.91 421.756 1323.42 420.261 1323.42 418.422C1323.42 416.582 1324.91 415.088 1326.75 415.088C1328.6 415.088 1330.09 416.582 1330.09 418.422C1330.09 420.261 1328.6 421.756 1326.75 421.756Z"
              fill="white"
            />
            <path
              d="M1379.03 439.459C1381.19 439.459 1382.95 437.71 1382.95 435.551C1382.95 433.392 1381.19 431.642 1379.03 431.642C1376.87 431.642 1375.12 433.392 1375.12 435.551C1375.12 437.71 1376.87 439.459 1379.03 439.459Z"
              fill="white"
            />
            <path
              d="M1431.37 457.221C1428.9 457.221 1426.89 455.209 1426.89 452.737C1426.89 450.266 1428.9 448.254 1431.37 448.254C1433.84 448.254 1435.86 450.266 1435.86 452.737C1435.86 455.209 1433.84 457.221 1431.37 457.221Z"
              fill="white"
            />
            <path
              d="M1483.71 474.982C1480.89 474.982 1478.59 472.683 1478.59 469.866C1478.59 467.05 1480.89 464.75 1483.71 464.75C1486.53 464.75 1488.83 467.05 1488.83 469.866C1488.83 472.683 1486.53 474.982 1483.71 474.982Z"
              fill="white"
            />
            <path
              d="M1536.05 492.916C1532.77 492.916 1530.18 490.272 1530.18 487.053C1530.18 483.834 1532.83 481.132 1536.05 481.132C1539.27 481.132 1541.91 483.776 1541.91 487.053C1541.91 490.329 1539.27 492.916 1536.05 492.916Z"
              fill="white"
            />
            <path
              d="M1588.38 510.849C1584.7 510.849 1581.71 507.86 1581.71 504.182C1581.71 500.503 1584.7 497.514 1588.38 497.514C1592.06 497.514 1595.06 500.503 1595.06 504.182C1595.06 507.86 1592.06 510.849 1588.38 510.849Z"
              fill="white"
            />
            <path
              d="M18.7834 55.9542C12.5145 55.9542 7.45328 50.896 7.45328 44.6307C7.45328 38.3654 12.5145 33.3072 18.7834 33.3072C25.0524 33.3072 30.1136 38.3654 30.1136 44.6307C30.1136 50.896 25.0524 55.9542 18.7834 55.9542Z"
              fill="white"
            />
            <path
              d="M71.2357 71.7612C65.7144 71.7612 61.2859 67.2778 61.2859 61.8172C61.2859 56.3566 65.7719 51.8732 71.2357 51.8732C76.6995 51.8732 81.1856 56.3566 81.1856 61.8172C81.1856 67.2778 76.6995 71.7612 71.2357 71.7612Z"
              fill="white"
            />
            <path
              d="M123.688 87.6831C118.914 87.6831 115.003 83.7745 115.003 79.0037C115.003 74.2329 118.914 70.3242 123.688 70.3242C128.462 70.3242 132.372 74.2329 132.372 79.0037C132.372 83.7745 128.462 87.6831 123.688 87.6831Z"
              fill="white"
            />
            <path
              d="M176.14 103.835C171.942 103.835 168.491 100.444 168.491 96.1901C168.491 91.9366 171.884 88.5453 176.14 88.5453C180.396 88.5453 183.79 91.9366 183.79 96.1901C183.79 100.444 180.396 103.835 176.14 103.835Z"
              fill="white"
            />
            <path
              d="M228.593 119.987C224.969 119.987 221.979 117.055 221.979 113.377C221.979 109.698 224.912 106.766 228.593 106.766C232.273 106.766 235.207 109.698 235.207 113.377C235.207 117.055 232.273 119.987 228.593 119.987Z"
              fill="white"
            />
            <path
              d="M280.987 136.368C277.824 136.368 275.236 133.782 275.236 130.62C275.236 127.459 277.824 124.872 280.987 124.872C284.151 124.872 286.739 127.459 286.739 130.62C286.739 133.782 284.151 136.368 280.987 136.368Z"
              fill="white"
            />
            <path
              d="M333.44 152.808C330.679 152.808 328.493 150.566 328.493 147.864C328.493 145.163 330.736 142.864 333.44 142.864C336.143 142.864 338.386 145.105 338.386 147.864C338.386 150.623 336.143 152.808 333.44 152.808Z"
              fill="white"
            />
            <path
              d="M385.892 169.247C383.534 169.247 381.636 167.35 381.636 164.993C381.636 162.637 383.534 160.74 385.892 160.74C388.25 160.74 390.148 162.637 390.148 164.993C390.148 167.35 388.25 169.247 385.892 169.247Z"
              fill="white"
            />
            <path
              d="M438.344 185.916C436.274 185.916 434.606 184.249 434.606 182.18C434.606 180.111 436.274 178.444 438.344 178.444C440.415 178.444 442.083 180.111 442.083 182.18C442.083 184.249 440.415 185.916 438.344 185.916Z"
              fill="white"
            />
            <path
              d="M490.797 202.643C489.014 202.643 487.576 201.206 487.576 199.424C487.576 197.642 489.014 196.205 490.797 196.205C492.579 196.205 494.017 197.642 494.017 199.424C494.017 201.206 492.579 202.643 490.797 202.643Z"
              fill="white"
            />
            <path
              d="M543.191 219.369C541.638 219.369 540.431 218.105 540.431 216.61C540.431 215.116 541.696 213.851 543.191 213.851C544.687 213.851 545.952 215.116 545.952 216.61C545.952 218.105 544.687 219.369 543.191 219.369Z"
              fill="white"
            />
            <path
              d="M595.644 237.016C593.861 237.016 592.423 235.579 592.423 233.797C592.423 232.015 593.861 230.578 595.644 230.578C597.427 230.578 598.864 232.015 598.864 233.797C598.864 235.579 597.427 237.016 595.644 237.016Z"
              fill="white"
            />
            <path
              d="M648.096 255.812C645.45 255.812 643.265 253.627 643.265 250.983C643.265 248.339 645.45 246.155 648.096 246.155C650.741 246.155 652.927 248.339 652.927 250.983C652.927 253.627 650.741 255.812 648.096 255.812Z"
              fill="white"
            />
            <path
              d="M700.548 275.182C696.695 275.182 693.589 272.078 693.589 268.227C693.589 264.376 696.695 261.272 700.548 261.272C704.402 261.272 707.507 264.376 707.507 268.227C707.507 272.078 704.402 275.182 700.548 275.182Z"
              fill="white"
            />
            <path
              d="M753 294.151C748.169 294.151 744.201 290.242 744.201 285.356C744.201 280.47 748.112 276.562 753 276.562C757.889 276.562 761.8 280.47 761.8 285.356C761.8 290.242 757.889 294.151 753 294.151Z"
              fill="white"
            />
            <path
              d="M805.453 312.199C800.104 312.199 795.79 307.888 795.79 302.543C795.79 297.197 800.104 292.886 805.453 292.886C810.801 292.886 815.115 297.197 815.115 302.543C815.115 307.888 810.801 312.199 805.453 312.199Z"
              fill="white"
            />
            <path
              d="M857.848 328.638C852.959 328.638 848.99 324.672 848.99 319.787C848.99 314.901 852.959 310.935 857.848 310.935C862.736 310.935 866.705 314.901 866.705 319.787C866.705 324.672 862.736 328.638 857.848 328.638Z"
              fill="white"
            />
            <path
              d="M910.3 343.008C906.964 343.008 904.261 340.307 904.261 336.973C904.261 333.639 906.964 330.938 910.3 330.938C913.635 330.938 916.339 333.639 916.339 336.973C916.339 340.307 913.635 343.008 910.3 343.008Z"
              fill="white"
            />
            <path
              d="M962.752 358.24C960.509 358.24 958.726 356.459 958.726 354.217C958.726 351.975 960.509 350.193 962.752 350.193C964.995 350.193 966.778 351.975 966.778 354.217C966.778 356.459 964.995 358.24 962.752 358.24Z"
              fill="white"
            />
            <path
              d="M1015.2 374.68C1013.36 374.68 1011.87 373.185 1011.87 371.346C1011.87 369.506 1013.36 368.012 1015.2 368.012C1017.04 368.012 1018.54 369.506 1018.54 371.346C1018.54 373.185 1017.04 374.68 1015.2 374.68Z"
              fill="white"
            />
            <path
              d="M1067.66 391.234C1066.16 391.234 1064.95 390.027 1064.95 388.532C1064.95 387.038 1066.16 385.831 1067.66 385.831C1069.15 385.831 1070.36 387.038 1070.36 388.532C1070.36 390.027 1069.15 391.234 1067.66 391.234Z"
              fill="white"
            />
            <path
              d="M1120.05 407.961C1118.84 407.961 1117.81 406.983 1117.81 405.719C1117.81 404.454 1118.79 403.477 1120.05 403.477C1121.32 403.477 1122.24 404.454 1122.24 405.719C1122.24 406.983 1121.26 407.961 1120.05 407.961Z"
              fill="white"
            />
            <path
              d="M1172.5 425.089C1171.35 425.089 1170.38 424.17 1170.38 422.963C1170.38 421.756 1171.3 420.836 1172.5 420.836C1173.71 420.836 1174.63 421.756 1174.63 422.963C1174.63 424.17 1173.71 425.089 1172.5 425.089Z"
              fill="white"
            />
            <path
              d="M1224.96 442.563C1223.63 442.563 1222.6 441.471 1222.6 440.207C1222.6 438.942 1223.63 437.85 1224.96 437.85C1226.28 437.85 1227.31 438.885 1227.31 440.207C1227.31 441.529 1226.22 442.563 1224.96 442.563Z"
              fill="white"
            />
            <path
              d="M1277.41 460.152C1275.86 460.152 1274.65 458.888 1274.65 457.393C1274.65 455.899 1275.91 454.634 1277.41 454.634C1278.9 454.634 1280.17 455.899 1280.17 457.393C1280.17 458.888 1278.9 460.152 1277.41 460.152Z"
              fill="white"
            />
            <path
              d="M1329.86 477.971C1327.96 477.971 1326.41 476.419 1326.41 474.58C1326.41 472.74 1327.96 471.131 1329.86 471.131C1331.76 471.131 1333.31 472.683 1333.31 474.58C1333.31 476.477 1331.76 477.971 1329.86 477.971Z"
              fill="white"
            />
            <path
              d="M1382.26 495.847C1380.01 495.847 1378.17 494.008 1378.17 491.766C1378.17 489.524 1380.01 487.685 1382.26 487.685C1384.5 487.685 1386.34 489.524 1386.34 491.766C1386.34 494.008 1384.5 495.847 1382.26 495.847Z"
              fill="white"
            />
            <path
              d="M1434.71 513.896C1431.95 513.896 1429.76 511.654 1429.76 508.953C1429.76 506.251 1431.95 504.009 1434.71 504.009C1437.47 504.009 1439.65 506.251 1439.65 508.953C1439.65 511.654 1437.41 513.896 1434.71 513.896Z"
              fill="white"
            />
            <path
              d="M1487.16 532.002C1483.94 532.002 1481.29 529.358 1481.29 526.196C1481.29 523.035 1483.94 520.334 1487.16 520.334C1490.38 520.334 1493.03 522.978 1493.03 526.196C1493.03 529.415 1490.44 532.002 1487.16 532.002Z"
              fill="white"
            />
            <path
              d="M1539.61 550.223C1535.82 550.223 1532.71 547.119 1532.71 543.326C1532.71 539.532 1535.82 536.428 1539.61 536.428C1543.41 536.428 1546.51 539.532 1546.51 543.326C1546.51 547.119 1543.41 550.223 1539.61 550.223Z"
              fill="white"
            />
            <path
              d="M1592.06 568.617C1587.58 568.617 1583.96 564.995 1583.96 560.512C1583.96 556.028 1587.58 552.407 1592.06 552.407C1596.55 552.407 1600.17 556.028 1600.17 560.512C1600.17 564.995 1596.55 568.617 1592.06 568.617Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_209_3">
          <rect width="1911" height="1935" fill="white" transform="translate(-156 -565)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlueStrip;
