import styled from 'styled-components';
import Card from '../Card';
import Row from '../Row';

export const Container = styled(Card)`
  flex-direction: row;
  align-items: center;
  gap: 50px;
  padding: 30px 80px;
  border: 1px solid var(--secondary);
  box-shadow: none;
  transform: translateY(-25%);

  @media only screen and (max-width: 1150px) {
    padding: 20px 40px;
    transform: translateY(0%);
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: -25%;
    transform: translateY(-50%);
    gap: 30px;
  }
`;

export const Content = styled(Row)`
  align-items: center;
  gap: 20px;
`;

export const Icon = styled(Row)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const Text = styled(Row)``;
