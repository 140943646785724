import styled, { css } from 'styled-components';
import { ThemeNameEnum, ThemeNameProps } from '../../utils';

const buttonReset = css`
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
`;

const Button = styled.button<ThemeNameProps>`
  ${buttonReset}

  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: normal;
  cursor: pointer;

  ${({ themeName }) => `background-color: var(--${themeName ?? ThemeNameEnum.CLEAR});`}
  ${({ themeName }) => `color: var(--${themeName ?? ThemeNameEnum.CLEAR}-negative);`}
`;

export default Button;
