import Column from './components/Column';
import Footer from './components/Footer';
import Header from './components/Header';
import About from './sections/About';
import Contact from './sections/Contact';
import IntegrateWithEverything from './sections/IntegrateWithEverything';
import Introduction from './sections/Introduction';
import OurClients from './sections/OurClients';
import WhyChoose from './sections/WhyChoose';

const App = () => (
  <Column style={{ maxWidth: '100%' }}>
    <Header id="header" />
    <Introduction id="introduction" />
    <About id="about" />
    <WhyChoose id="why-choose" />
    <OurClients id="our-clients" />
    <IntegrateWithEverything id="integrate-with-everything" />
    <Contact id="contact" />
    <Footer id="footer" />
  </Column>
);

export default App;
