import styled from 'styled-components';
import { ThemeNameEnum, ThemeNameProps } from '../../utils';

const Card = styled.div<ThemeNameProps>`
  display: flex;
  box-shadow: 0px 6px 24px rgba(55, 135, 255, 0.12);
  border-radius: 16px;

  ${({ themeName }) => `background-color: var(--${themeName ?? ThemeNameEnum.WHITE});`}
  ${({ themeName }) => `color: var(--${themeName ?? ThemeNameEnum.WHITE}-negative);`}
`;

export default Card;
