import { FC, HTMLAttributes } from 'react';
import BestOption from '../../components/BestOption';
import Button from '../../components/Button';
import Column from '../../components/Column';
import FittingComfortBox from '../../components/FittingComfortBox';
import { anchorTo, FittingComfortCommon, ThemeNameEnum } from '../../utils';

import {
  Container,
  Content,
  CustomFemaleSmallMannequin,
  FittingComfortBoxes,
  LeftContent,
  MannequinContainer,
  MannequinWithBlurContainer,
  RightContent,
  Text,
  Title,
} from './styles';

type IntroductionProps = HTMLAttributes<HTMLDivElement>;

const Introduction: FC<IntroductionProps> = (props) => {
  const title = `O provador virtual ideal para o seu ecommerce`;
  const text = `Tornar a escolha de compra dos seus clientes em uma experiência fácil, encantadora e precisa. Além disso, reduzir as chances de trocas ou devoluções dos seus produtos!\nFaz sentido para você?`;

  return (
    <Container {...props}>
      <Content className="container">
        <LeftContent>
          <Column>
            <Title>{title}</Title>
            <Text>{text}</Text>
          </Column>
          <Button themeName={ThemeNameEnum.PRIMARY} onClick={() => anchorTo('contact')}>
            Teste agora mesmo
          </Button>
        </LeftContent>
        <RightContent>
          <MannequinContainer>
            <CustomFemaleSmallMannequin />
          </MannequinContainer>
          <FittingComfortBoxes>
            <Button
              style={{
                backgroundColor: '#fff',
                boxShadow: '0px 6px 24px rgba(55, 135, 255, 0.12)',
                borderRadius: 16,
              }}
            >
              <BestOption size="M" />
            </Button>
            <FittingComfortBox fittingComfortValue={FittingComfortCommon.BEST_FIT} />
            <FittingComfortBox fittingComfortValue={FittingComfortCommon.BEST_FIT} />
            <FittingComfortBox fittingComfortValue={FittingComfortCommon.SLIGHTLY_LOOSE} />
          </FittingComfortBoxes>
          <MannequinWithBlurContainer />
        </RightContent>
      </Content>
    </Container>
  );
};

export default Introduction;
