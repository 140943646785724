import styled, { css } from 'styled-components';
import PhoneInput from '../PhoneInput';

export const INPUT_HORIZONTAL_INSET = 20;

export const INPUT_TEXT_CSS = css`
  font-size: 14px;
  font-weight: 500;
  color: var(--quaternary);
`;

const inputCommonStyles = css`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding: 5px ${INPUT_HORIZONTAL_INSET}px;
  outline: none;
  z-index: 10;
`;

export const CustomInput = styled.input`
  ${INPUT_TEXT_CSS}
  ${inputCommonStyles}
`;

export const CustomTextArea = styled.textarea`
  ${INPUT_TEXT_CSS}
  ${inputCommonStyles}

  resize: none;
`;

export const CustomPhoneInput = styled(PhoneInput)`
  ${INPUT_TEXT_CSS}
  ${inputCommonStyles}
`;
