import styled from 'styled-components';
import Row from '../Row';

export const ICON_SIZE = 18;

export const Container = styled(Row)`
  border: 1px solid transparent;
  padding: 8px 16px 8px 12px;
  border-radius: 4px;
  align-items: center;
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #000;
  inline-size: min-content;
`;
