import styled from 'styled-components';
import { TABLET_SCREEN_HEIGHT_PERCENTAGE } from '../../components/BlueStrip';
import Column from '../../components/Column';
import Row from '../../components/Row';

export const SectionContainer = styled.section`
  position: relative;
  flex: none;
  max-width: none;
  padding: 0;

  @media only screen and (max-width: 1150px) {
    min-height: ${TABLET_SCREEN_HEIGHT_PERCENTAGE * 100}vh;
  }

  @media only screen and (max-width: 600px) {
    min-height: 100vh;
  }
`;

export const Container = styled(Column)`
  ::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50%;
    background-color: #fbfbfb;
    z-index: -2;
  }
`;

export const Content = styled(Row)`
  align-items: center;
  height: 100%;
  padding: 20px 0 80px 0;
  gap: 100px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px 0 0px 0;
  }
`;

export const Text = styled.p`
  color: var(--white);
  font-size: 18px;
  white-space: pre-wrap;

  @media only screen and (max-width: 600px) {
    text-align: justify;
  }
`;
