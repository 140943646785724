import { forwardRef, HTMLAttributes } from 'react';
import { CustomInput, CustomPhoneInput, CustomTextArea } from './styles';

export type InputWithTextAreaProps = {
  type?: string;
} & HTMLAttributes<HTMLInputElement> &
  HTMLAttributes<HTMLTextAreaElement>;

const InputWithTextArea = forwardRef<HTMLInputElement & HTMLTextAreaElement, InputWithTextAreaProps>(
  ({ type, ...props }, ref) => {
    switch (type) {
      case 'textarea':
        return (
          <CustomTextArea
            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
            {...(props as HTMLAttributes<HTMLTextAreaElement>)}
          />
        );
      case 'tel':
        return (
          <CustomPhoneInput
            ref={ref as React.ForwardedRef<HTMLInputElement>}
            {...(props as HTMLAttributes<HTMLInputElement>)}
          />
        );
      default:
        return (
          <CustomInput
            ref={ref as React.ForwardedRef<HTMLInputElement>}
            {...(props as HTMLAttributes<HTMLInputElement>)}
          />
        );
    }
  },
);

export default InputWithTextArea;
