import styled from 'styled-components';
import Row from '../Row';

export const Container = styled(Row)`
  align-items: center;
  gap: 5px;
`;

export const SizeBox = styled.div`
  border: 1px solid var(--green);
  border-radius: 4px;
  padding: 1px 4px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #000;
`;
