import { FC, HTMLAttributes } from 'react';

import { Container, Content, LogoButton } from './styles';
import Row from '../Row';
import Button from '../Button';
import { anchorTo, ThemeNameEnum } from '../../utils';
import { ProvefacilLogoColorful } from '../../assets/vectors/logo';

type HeaderProps = HTMLAttributes<HTMLDivElement>;

const Header: FC<HeaderProps> = (props) => (
  <Container {...props}>
    <Content className="container">
      <LogoButton onClick={() => anchorTo()}>
        <ProvefacilLogoColorful style={{ maxWidth: 150, width: 150 }} />
      </LogoButton>
      <Row style={{ gap: 20 }}>
        {/* <Button>Preços</Button> */}
        <Button themeName={ThemeNameEnum.PRIMARY_100} onClick={() => anchorTo('contact')}>
          Quero iniciar
        </Button>
      </Row>
    </Content>
  </Container>
);

export default Header;
