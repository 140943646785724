import styled from 'styled-components';
import Column from '../../components/Column';
import Differentials from '../../components/Differentials';
import Row from '../../components/Row';

export const SectionContainer = styled.section`
  max-width: none;
  padding: 0;
  background-color: #fbfbfb;
  z-index: -2;
  padding-bottom: 40px;
`;

export const Container = styled(Column)`
  align-self: center;
  padding-top: 20px;
  min-height: 100vh;
  gap: 80px;

  @media only screen and (max-width: 600px) {
    gap: 40px;
    padding-top: 40px;
  }

  @media only screen and (max-width: 375px) {
    padding-top: 80px;
  }
`;

export const Content = styled(Row)`
  gap: 70px;

  @media only screen and (max-width: 1150px) {
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: 600px) {
    gap: 40px;
  }
`;

export const CustomDifferentials = styled(Differentials)`
  @media only screen and (max-width: 1150px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export const Text = styled.p`
  font-size: 18px;
  white-space: pre-wrap;
`;
