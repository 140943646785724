import { forwardRef, useCallback, useState } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { InputWithTextAreaProps } from '../InputWithTextArea';

import { Container, CustomInputWithTextArea, ErrorText, Label, LabelContainer, Wrapper } from './styles';

export type InputProps<T extends FieldValues = any> = {
  label: string;
  error?: FieldErrors<T>[keyof FieldErrors<T>];
  isTouched?: boolean;
} & InputWithTextAreaProps;

const Input = forwardRef<HTMLInputElement & HTMLTextAreaElement, InputProps>(
  ({ type, label = 'text', isTouched, error, onFocus, onBlur, ...rest }, ref) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleOnFocus: React.FocusEventHandler<HTMLInputElement & HTMLTextAreaElement> = useCallback(
      (e) => {
        setIsFocused(true);
        onFocus && onFocus(e);
      },
      [onFocus],
    );

    const handleOnBlur: React.FocusEventHandler<HTMLInputElement & HTMLTextAreaElement> = useCallback(
      (e) => {
        setIsFocused(false);
        onBlur && onBlur(e);
      },
      [onBlur],
    );

    return (
      <Wrapper>
        <Container
          isTouched={isTouched}
          isValid={!error}
          isFocused={isFocused}
          style={type === 'textarea' ? { height: 100 } : undefined}
        >
          <CustomInputWithTextArea ref={ref} type={type} {...rest} onFocus={handleOnFocus} onBlur={handleOnBlur} />
          <LabelContainer className="label-container">
            <Label isFocused={isFocused}>{label}</Label>
          </LabelContainer>
        </Container>
        {typeof error?.message === 'string' && !!error?.message?.length && <ErrorText>{error?.message}</ErrorText>}
      </Wrapper>
    );
  },
);

export default Input;
