import styled from 'styled-components';
import Button from '../Button';
import Row from '../Row';

export const Container = styled.section`
  align-self: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  z-index: 10;
`;

export const Content = styled(Row)`
  position: relative;
  width: 100%;
  padding: 15px 0 30px 0;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--quaternary-400);
  gap: 60px;
`;

export const Text = styled.span`
  font-size: 14px;
  color: var(--quaternary-600);
`;

const FAB_SIZE = 50;

export const FAB = styled(Button)`
  position: absolute;
  top: -${FAB_SIZE + 20}px;
  right: 0;
  align-items: center;
  justify-content: center;
  height: ${FAB_SIZE}px;
  width: ${FAB_SIZE}px;
  max-height: ${FAB_SIZE}px;
  max-width: ${FAB_SIZE}px;
  border-radius: 100%;
`;
