import { FC, HTMLAttributes } from 'react';
import { ProvefacilLogoWhite } from '../../assets/vectors/logo';
import BlueStrip from '../../components/BlueStrip';
import Column from '../../components/Column';
import HighlightsCard from '../../components/HighlightsCard';

import { SectionContainer, Container, Text, Content } from './styles';

type AboutProps = HTMLAttributes<HTMLDivElement>;

const About: FC<AboutProps> = (props) => {
  const text = `A Prove Fácil é uma empresa especializada em soluções de e-commerce inovadoras, que utiliza a tecnologia para tornar a jornada de compra dos clientes mais fácil, precisa e agradável. Nós acreditamos que a tecnologia é a chave para o sucesso no mundo atual e estamos sempre em busca de novas maneiras de melhorar a experiência de compra dos nossos clientes.\n\nUma das nossas principais soluções é o provador virtual, que permite que os clientes experimentem roupas e acessórios antes de comprar, sem precisar sair de casa.`;

  return (
    <SectionContainer {...props}>
      <BlueStrip />
      <Container className="container">
        <HighlightsCard />
        <Content>
          <Column style={{ flex: 1, alignItems: 'flex-end' }}>
            <ProvefacilLogoWhite style={{ maxWidth: 280, width: 280 }} />
          </Column>
          <Column style={{ flex: 1 }}>
            <Text>{text}</Text>
          </Column>
        </Content>
      </Container>
    </SectionContainer>
  );
};

export default About;
