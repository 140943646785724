import styled, { css } from 'styled-components';
import { ThemeNameProps } from '../../utils';
import InputWithTextArea from '../InputWithTextArea';
import { INPUT_HORIZONTAL_INSET, INPUT_TEXT_CSS } from '../InputWithTextArea/styles';

type ValidationProps = {
  isValid?: boolean;
  isTouched?: boolean;
};

type FocusProps = {
  isFocused: boolean;
};

type ContainerProps = ValidationProps & FocusProps & ThemeNameProps;

const labelFocusedCss = css`
  font-size: 12px;
  transform: translateY(-23px);
`;

const labelBlurredCss = css`
  ${INPUT_TEXT_CSS}
  transform: translateY(0px);
`;

export const Wrapper = styled.div``;

export const Container = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  height: 50px;
  border: ${({ isFocused, isValid, isTouched }) => {
    const common = '1px solid var(--quaternary-300)';

    if (isFocused) {
      return '1px solid var(--secondary)';
    }

    if (isTouched === true) {
      if (isValid === true) {
        return '1px solid var(--success)';
      }

      if (isValid === false) {
        return '1px solid var(--danger)';
      }
    }

    return common;
  }};
  border-radius: 14px;
  padding: 5px;
  max-width: 450px;

  input:not([value='']) ~ .label-container > span {
    ${labelFocusedCss}
  }
`;

export const LabelContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${INPUT_HORIZONTAL_INSET}px;
  display: flex;
  padding: 15px 0;
  z-index: 0;
`;

export const Label = styled.span<FocusProps>`
  ${INPUT_TEXT_CSS}

  color: var(--tertiary-300) !important;
  white-space: nowrap;
  background: #fff;
  padding: 0px 5px;
  transition: transform 0.1s, font-size 0.1s;

  ${({ isFocused }) => (isFocused ? labelFocusedCss : labelBlurredCss)}
`;

export const CustomInputWithTextArea = styled(InputWithTextArea)``;

export const ErrorText = styled.span`
  font-size: 12px;
  color: var(--danger);
  padding: 5px 0 0 5px;
`;
