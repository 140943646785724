import styled from 'styled-components';

export type ColumnProps = React.HTMLAttributes<HTMLDivElement>;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Column;
