import styled, { css } from 'styled-components';
import Card from '../../components/Card';
import Column from '../../components/Column';

export const Container = styled.section`
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  gap: 50px;

  @media only screen and (max-width: 600px) {
    min-height: 100vh;
    flex-direction: column;
    align-items: stretch;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 20px;
  }
`;

export const LeftContent = styled(Column)`
  flex: 1;
  gap: 20px;

  @media only screen and (max-width: 600px) {
    flex: none;
  }
`;

export const RightContent = styled(Column)`
  position: relative;
  flex: 2;
  justify-content: center;
  align-items: center;
  min-width: 100px;

  @media only screen and (max-width: 600px) {
    flex: 1;
  }
`;

export const Title = styled.h3``;

export const Text = styled.p`
  @media only screen and (max-width: 600px) {
    text-align: justify;
  }
`;

const cardCommonStyles = css`
  align-self: flex-start;
  align-items: center;
  padding: 0 15px;
  height: 80px;
`;

export const GoogleShoppingCard = styled(Card)`
  ${cardCommonStyles}
`;

export const ProveFacilCard = styled(Card)`
  ${cardCommonStyles}
`;
