import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  gap: 15px;
  padding-top: 30px;

  > div {
    width: 100%;
  }
`;
