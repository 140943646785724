import { FC, HTMLAttributes } from 'react';
import { ModelThreeDIcon, RulerIcon, TShirtIcon } from '../../assets/vectors/icons';
import { Container, Content, IconContainer, Text, TextsContainer, Title } from './styles';

const ICON_SIZE = 25;

type Differential = {
  id: number;
  Icon: FC<any>;
  title: string;
  text: string;
};

type DifferentialsProps = HTMLAttributes<HTMLDivElement>;

const Differentials: FC<DifferentialsProps> = (props) => {
  const differentials: Differential[] = [
    {
      id: 1,
      Icon: RulerIcon,
      title: 'Medidas personalizáveis',
      text: 'Medidas individuais para cada produto, gerenciadas facilmente pelo seu time. A Prove Fácil ajuda a reduzir o número de trocas e devoluções, pois os clientes podem ter uma ideia melhor do que estão comprando.',
    },
    {
      id: 2,
      Icon: TShirtIcon,
      title: 'Roupas, sapatos e acessórios',
      text: 'Ajude os seus clientes a comprarem de maneira correta, independente do produto.',
    },
    {
      id: 3,
      Icon: ModelThreeDIcon,
      title: 'Modelo de corpo 3D personalizado',
      text: 'Tecnologia inovadora e de ponta, que ajuda empresas a se adaptarem aos desafios constantes do mundo globalizado. Seu cliente merece o que há de melhor!',
    },
  ];

  return (
    <Container {...props}>
      {differentials.map((differential) => (
        <Content key={differential.id}>
          <IconContainer>
            <differential.Icon style={{ width: ICON_SIZE, height: ICON_SIZE }} />
          </IconContainer>
          <TextsContainer>
            <Title>{differential.title}</Title>
            <Text>{differential.text}</Text>
          </TextsContainer>
        </Content>
      ))}
    </Container>
  );
};

export default Differentials;
