import styled from 'styled-components';
import Button from '../Button';
import Row from '../Row';

export const Container = styled.section`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  max-width: none;
  padding: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
`;

export const Content = styled(Row)`
  width: 100%;
  max-width: 1024px;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1150px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const LogoButton = styled(Button)`
  padding: 0;
  border-radius: 0;
  user-select: none;

  :focus,
  :active,
  :hover {
    background: none;
    box-shadow: none;
    outline: none;
  }
`;
