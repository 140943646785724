import { FC, HTMLAttributes } from 'react';
import Circle from '../../components/Circle';
import Column from '../../components/Column';
import { ReactComponent as AppMockups } from '../../assets/vectors/app-mockup.svg';

import { SectionContainer, Container, Content, CustomDifferentials } from './styles';

type WhyChooseProps = HTMLAttributes<HTMLDivElement>;

const WhyChoose: FC<WhyChooseProps> = (props) => {
  const title = `Por que escolher a Prove Fácil?`;

  return (
    <SectionContainer {...props}>
      <Container className="container">
        <h3 style={{ alignSelf: 'center' }}>{title}</h3>
        <Content>
          <Column style={{ flex: 2 }}>
            <CustomDifferentials />
          </Column>
          <Column style={{ position: 'relative', flex: 3, justifyContent: 'flex-end' }}>
            <Column style={{ position: 'relative' }}>
              <Circle
                half
                size="100%"
                fill="var(--primary-100)"
                style={{
                  position: 'absolute',
                  transform: 'rotate(180deg)',
                  zIndex: -1,
                }}
              />
              <AppMockups width="100%" height="100%" style={{ maxWidth: 525, alignSelf: 'center' }} />
            </Column>
          </Column>
        </Content>
      </Container>
    </SectionContainer>
  );
};

export default WhyChoose;
