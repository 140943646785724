import styled from 'styled-components';
import Column from '../Column';

export const Container = styled(Column)`
  gap: 30px;
  justify-content: center;
`;

export const Content = styled(Column)`
  gap: 20px;
`;

export const IconContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--secondary-100);
  border-radius: 16px;
  color: var(--tertiary);
`;

export const TextsContainer = styled(Column)`
  gap: 10px;
`;

export const Title = styled.h6``;

export const Text = styled.p`
  @media only screen and (max-width: 600px) {
    text-align: justify;
  }
`;
