import { AsYouType } from 'libphonenumber-js';
import { ChangeEventHandler, forwardRef, HTMLAttributes, useCallback } from 'react';

export type PhoneInputProps = HTMLAttributes<HTMLInputElement>;

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(({ onChange, ...rest }, ref) => {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const phoneNumber = new AsYouType('BR').input(e.target.value);
      e.target.value = phoneNumber;

      onChange && onChange(e);
    },
    [onChange],
  );

  return <input ref={ref} onChange={handleOnChange} {...rest} />;
});

export default PhoneInput;
